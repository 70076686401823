import * as React from 'react'
import { useFormContext } from 'react-hook-form'

// components
import { Grid, InputLabel, FormControl, Select, Box } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControl'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { AilmentSelectorForm } from '~/pages/AppointmentIntake/forms'
import { isSdmEnterprise } from '~/util/enterpriseMethods'
import { useMethodsDropDownVerbiage } from './useMethodsDropDownVerbiage'
import { EAppointmentTypeMethod } from '~/graphql/types/schemaNode.type'
import { isValidAppointmentMethod } from '~/util/appointmentHelpers'

type MethodsDropDownMenuProps = {
  enabledMethods: string[]
  appointmentMethod?: string
}

export const MethodsDropDownMenu: React.FC<MethodsDropDownMenuProps> = ({
  enabledMethods,
  appointmentMethod = undefined,
}) => {
  const { formState, setValue } = useFormContext<AilmentSelectorForm>()
  const verbiage = useMethodsDropDownVerbiage()

  const hasOnsiteMethod = enabledMethods.find((item) => item === 'ONSITE')
  const initialMethodValue =
    appointmentMethod ?? (hasOnsiteMethod ? 'ONSITE' : enabledMethods[0])

  // SDM rule:
  // user do not see a dropdown selector and the value defaults to Onsite if only Onsite is available
  // if value != Onsite, then show the dropdown selector and mention the method
  const hideDropDown =
    isSdmEnterprise() && hasOnsiteMethod && enabledMethods.length === 1

  // dropdown will be disabled if either the appointment method is a valid one, or if there's only one method available
  const disableDropdown =
    Boolean(appointmentMethod) ||
    enabledMethods.length <= 1 ||
    formState.isSubmitting
  React.useEffect(() => {
    setValue('method', initialMethodValue)
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const method = isValidAppointmentMethod(event.target.value)
      ? event.target.value
      : EAppointmentTypeMethod.ONSITE
    setValue('method', method)
  }

  return hideDropDown ? null : (
    <Box mb={3}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Heading4>{verbiage.headingLabel}</Heading4>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormControlLabel variant="outlined">
              <InputLabel>{verbiage.inputLabel}</InputLabel>
              <Select
                native
                onChange={handleChange}
                label={verbiage.inputLabel}
                disabled={disableDropdown}
                defaultValue={initialMethodValue}
              >
                {enabledMethods.map(
                  (method: keyof typeof verbiage.methodMap, index) => (
                    <option key={index} value={method}>
                      {verbiage.methodMap[method]}
                    </option>
                  )
                )}
              </Select>
            </FormControlLabel>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}
