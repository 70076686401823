import React, { useEffect, useState, lazy } from 'react'
import { Switch, Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  getEnterpriseCode,
  isJcgBrunet,
  isJcgBrunetJointSubdomain,
  isSdmEnterprise,
  isSdmEnterpriseJointSubdomain,
  isEnterprise,
} from '~/util/enterpriseMethods'
import moment from './util/moment.js'
import dayjs from 'dayjs'
import 'dayjs/locale/fr-ca'
import 'dayjs/locale/fr'
import { isFirefox, isIE, isEdge, isEdgeChromium } from '~/util/browser'
import { initializeFreshpaint } from './util/freshpaint/initializeFreshpaint'
import { isDev, isLocalHost, isSandbox, isStaging } from './util/envMethods'
import Loading from './components/loading'
import { useIsUserLoggedIn } from './auth'

import {
  AppointmentIntakeRoutes,
  AppointmentManagementRoutes,
  DevRoutes,
} from './routes'
import { useMedMeTranslation } from './hooks/useMedMeTranslation'
import { getIsBillingEnabled } from './util/featureAccess'
import usePageHeader from './hooks/usePageHeader'
import { useRedirectToSDM } from './hooks/useRedirectToSDM'
import { withMonitoringProfiler, MonitoredRoute } from './tools/monitoring'
import _ from 'lodash'
import {
  useUpdateFeatureGatingContextFromRedux,
  useFeatureFlag,
  FeatureFlag,
} from '~/tools/featureGating'
import { PharmacistWaitlistRoutes } from './routes/PharmacistWaitlistRoutes'

const PatientList = lazy(() => import('./pages/pharmacistSide/patientList'))
const PatientProfile = lazy(() =>
  import('./pages/pharmacistSide/patientProfile')
)
const PatientImport = lazy(() =>
  import('./pages/pharmacistSide/patientImport/patientImport')
)

const PharmacistDashboard = lazy(() =>
  import('./pages/pharmacistSide/dashboard')
)
const ExploDashboard = lazy(() =>
  import('./pages/pharmacistSide/exploDashboard')
)
const PharmacistAppointmentList = lazy(() =>
  import('./pages/pharmacistSide/appointmentList')
)
const PharmacistConsultationListContainer = lazy(() =>
  import('./jcg-features/consultationList/index.js')
)

const Login = lazy(() => import('./pages/pharmacistSide/login'))
const ConsultationRoom = lazy(() => import('./pages/consultationRoom'))
const ResetPassword = lazy(() => import('./pages/resetPassword'))
const PharmacistRegistration = lazy(() =>
  import('./pages/pharmacistRegistration')
)
const EnterprisePharmacistRegistration = lazy(() =>
  import('./pages/enterprisePharmacistRegistration')
)
const RescheduleAppointment = lazy(() => import('./pages/reschedule'))
const CancelAppointment = lazy(() => import('./pages/cancelAppointment'))
const MedscheckPdf = lazy(() =>
  import('./pdfs/medscheck/medscheckPdfContainer')
)
const Settings = lazy(() => import('./pages/settings'))
const DownloadDocumentation = lazy(() =>
  import('./pages/downloadDocumentation')
)
const PatientRegistration = lazy(() =>
  import('./pages/patientRegistrationV2/patientRegistrationContainer')
)
const SamlLogin = lazy(() => import('./pages/pharmacistSide/samlLogin'))
const StoreSelector = lazy(() => import('./pages/pharmacistSide/StoreSelector'))
const PharmacistFirstTimeLogin = lazy(() =>
  import('./pages/pharmacistFirstTimeLogin')
)
const PatientRedirect = lazy(() => import('./pages/patientRedirect'))
const FormListContainer = lazy(() => import('./jcg-features/formList/index.js'))

const Billing = lazy(() => import('./pages/pharmacistSide/billing/billing.tsx'))
const CovidScreening = lazy(() =>
  import('./pages/covidScreening/covidScreeningContainer')
)
const StoreList = lazy(() => import('./pages/adminSide/storeList'))
const AdminStore = lazy(() => import('./pages/adminSide/adminStore'))
const StaySwitchCovidVaccine = lazy(() =>
  import('./pages/staySwitchCovidVaccine')
)
const Unsubscribe = lazy(() => import('./pages/unsubscribe'))
const Checkin = lazy(() => import('./pages/Checkin'))
const UnsupportedBrowser = lazy(() =>
  import('./pages/pharmacistSide/unsupportedBrowser')
)
const QuestionTools = lazy(() =>
  import('./tools/QuestionBuilder/questionBuilder')
)
const PdfMappingTool = lazy(() =>
  import('./tools/PdfMappingTool/pdfMappingTool')
)
const LoginSelector = lazy(() =>
  import('./pages/pharmacistSide/LCLSDMLoginSelector/loginSelector')
)
const JeanCoutuLoginSelector = lazy(() =>
  import('./jcg-features/JeanCoutuLoginSelector/index.js')
)
const LoginLanding = lazy(() => import('./pages/LoginLanding'))
const SelectLanguage = lazy(() => import('./pages/selectLanguage'))

// This list of tenants enables the :store/schedule/groups route, this
// needs to be updated when a new enterprise tenant is added.

// That route uses a redirection hook to route legacy groups to the new /activity-groups/ URL.

// Refer to src/hooks/useAagIntake/useAagIntake.ts
const GROUP_URL_PHARMACIST_NAME = [
  'REXALL',
  'SDM',
  'LCL',
  'SOF',
  'MINT',
  'METRO',
  'PHX',
  'MEDME',
]

// trigger build can you please build
const unSupportedBrowser = () => {
  return isFirefox || isIE || isEdge || isEdgeChromium || false
}

const App = () => {
  const { data } = useIsUserLoggedIn()
  const pharmacist = useSelector((state) => state.pharmacist)
  const pharmacy = useSelector((state) => state.pharmacy)
  const [isFreshPaintLoaded, setIsFreshPaintLoaded] = useState(false)
  const [isJointLoginRedirect, setIsJointLoginRedirect] = useState(false)
  const { i18n } = useMedMeTranslation()
  const currentLanguage = i18n.language
  const { state: locationState } = useLocation()
  const isJointLoginAvailable = isSdmEnterprise() || isJcgBrunet()
  const noAccountFound = locationState?.noAccountFound

  useUpdateFeatureGatingContextFromRedux()

  useRedirectToSDM()
  usePageHeader()

  const isPharmacistReportsDisabled = useFeatureFlag(
    FeatureFlag.HIDE_PHARMACIST_REPORTS,
    false
  )
  const isPharmacistWaitlistFeaturesDisabled = useFeatureFlag(
    FeatureFlag.HIDE_PHARMACIST_WAITLIST,
    false
  )

  useEffect(() => {
    // Ensure date/time and day of week locale are set
    // according to the current language
    moment.locale(currentLanguage)
    dayjs.locale(currentLanguage)
  }, [currentLanguage])

  useEffect(() => {
    // Setup Freshpaint
    initializeFreshpaint(
      pharmacy,
      pharmacist,
      isFreshPaintLoaded,
      setIsFreshPaintLoaded
    )
  }, [pharmacy, pharmacist, isFreshPaintLoaded])

  useEffect(() => {
    const intercomData = {
      app_id: process.env.INTERCOM_APP_ID,
    }

    if (data?.isLoggedIn || data?.isAdmin) {
      const pharmacistData = _.omit(pharmacist, [
        '__typename',
        'isFirstTimeLogin',
      ])

      // Add pharmacist data to intercom
      Object.assign(intercomData, pharmacistData, {
        user_id: pharmacistData?.id,
      })

      // Setup Canny
      Canny('identify', {
        appID: process.env.CANNY_APP_ID,
        user: {
          email: pharmacist.email,
          name: `${pharmacist.firstName} ${pharmacist.lastName}`,
          id: pharmacist.id,
          avatarURL: process.env.REACT_APP_S3_BASE_URL + pharmacy.logoLong,
          companies: [
            {
              id: pharmacy.id,
              name: pharmacy.name,
            },
          ],
        },
      })
    }
    window.Intercom('boot', intercomData)
    return () => window.Intercom('shutdown')
  }, [data, pharmacist, pharmacy])

  useEffect(() => {
    if (isJointLoginRedirect) {
      const host = (() => {
        if (isSdmEnterprise()) {
          let postfix = ''
          if (isDev) postfix = 'dev'
          else if (isSandbox) postfix = 'sandbox'
          else if (isStaging) postfix = '.staging'
          return `http://lclsdm${postfix}.medmeapp.com`
        } else if (isJcgBrunet()) {
          return `https://consultation${(isDev && '-dev') || ''}.centrerx.com`
        } else {
          return ''
        }
      })()

      if (noAccountFound) {
        window.location.href = `${host}/login?noAccountFound=true`
        window.history.replaceState({}, document.title)
      } else {
        window.location.href = `${host}/login`
      }
    }
  }, [isJointLoginRedirect, noAccountFound])

  if (data?.isAdmin) {
    if (isSdmEnterprise() && unSupportedBrowser()) return <UnsupportedBrowser />
    return (
      <Switch>
        <MonitoredRoute path="/admin/store/:pharmacyId/:sectionTabIndex?">
          <AdminStore />
        </MonitoredRoute>
        <MonitoredRoute>
          <StoreList />
        </MonitoredRoute>
      </Switch>
    )
  }

  // Logged In Routes
  if (data?.isLoggedIn) {
    return (
      <Switch>
        <MonitoredRoute path="/pharmacist/patientList">
          <PatientList />
        </MonitoredRoute>
        <MonitoredRoute path="/pharmacist/patient/:patientId/:appointmentId?">
          <PatientProfile />
        </MonitoredRoute>
        <MonitoredRoute path="/pharmacist/dashboard">
          <PharmacistDashboard />
        </MonitoredRoute>
        {getIsBillingEnabled() && (
          <MonitoredRoute path="/pharmacist/billing">
            <Billing />
          </MonitoredRoute>
        )}
        {!isPharmacistReportsDisabled && (
          <MonitoredRoute path="/pharmacist/reports">
            <ExploDashboard />
          </MonitoredRoute>
        )}
        {isDev && (
          <MonitoredRoute path="/tools/questions">
            <QuestionTools />
          </MonitoredRoute>
        )}
        {isDev && (
          <MonitoredRoute path="/tools/mapping">
            <PdfMappingTool />
          </MonitoredRoute>
        )}
        {!isJcgBrunet(pharmacy.enterprise) && (
          <MonitoredRoute path="/pharmacist/appointmentList">
            <PharmacistAppointmentList />
          </MonitoredRoute>
        )}
        {isJcgBrunet(pharmacy.enterprise) && (
          <MonitoredRoute path="/pharmacist/consultationList">
            <PharmacistConsultationListContainer />
          </MonitoredRoute>
        )}
        {isJcgBrunet(pharmacy.enterprise) && (
          <MonitoredRoute path="/pharmacist/forms">
            <FormListContainer />
          </MonitoredRoute>
        )}
        <MonitoredRoute path="/pharmacist/appointmentList">
          <PharmacistAppointmentList />
        </MonitoredRoute>

        {PharmacistWaitlistRoutes({
          isDisabled: isPharmacistWaitlistFeaturesDisabled,
        })}

        <MonitoredRoute path="/consultation/:id/:roomId/:patientId">
          <ConsultationRoom />
        </MonitoredRoute>
        <MonitoredRoute path="/pharmacist/register">
          <PharmacistRegistration />
        </MonitoredRoute>
        <MonitoredRoute path="/pharmacist/settings/:tabId?">
          <Settings />
        </MonitoredRoute>
        <MonitoredRoute path="/medscheckPdf/:patientId/:appointmentId">
          <MedscheckPdf />
        </MonitoredRoute>
        <MonitoredRoute path="/pharmacistFirstTimeLogin">
          <PharmacistFirstTimeLogin />
        </MonitoredRoute>
        <MonitoredRoute path="/selectStore">
          <StoreSelector />
        </MonitoredRoute>
        <MonitoredRoute path="/loginLanding">
          <LoginLanding />
        </MonitoredRoute>
        <MonitoredRoute path="/loadDashboard">
          <Redirect to="/" />
        </MonitoredRoute>
        <MonitoredRoute path="/ip/:payload">
          <PatientImport />
        </MonitoredRoute>
        <MonitoredRoute>
          <PharmacistDashboard />
        </MonitoredRoute>
      </Switch>
    )
  } else if (
    isSdmEnterpriseJointSubdomain(window.location.hostname) ||
    (isLocalHost && window.location.pathname === '/jointLoginTest')
  ) {
    // Routes for the lclsdm*___*.medmeapp.com subdomain
    return (
      <Switch>
        <MonitoredRoute path="/login">
          <LoginSelector />
        </MonitoredRoute>
        <MonitoredRoute path="/">
          {/* This entire subdomain is only used for login. Redirect to login unless testing on localhost */}
          {isLocalHost ? <LoginSelector /> : <Redirect to="/login" />}
        </MonitoredRoute>
      </Switch>
    )
  } else if (
    isJcgBrunetJointSubdomain(window.location.hostname) ||
    (isLocalHost && window.location.pathname === '/jointLoginTestJcg')
  ) {
    // Routes for the jcgbrunet*___*.medmeapp.com subdomain
    return (
      <Switch>
        <MonitoredRoute path="/login">
          <JeanCoutuLoginSelector />
        </MonitoredRoute>
        <MonitoredRoute path="/">
          {/* This entire subdomain is only used for login. Redirect to login unless testing on localhost */}
          {isLocalHost ? <JeanCoutuLoginSelector /> : <Redirect to="/login" />}
        </MonitoredRoute>
      </Switch>
    )
  } else if (isEnterprise()) {
    // Enterprise Routes (not logged in)
    return (
      <Switch>
        {AppointmentIntakeRoutes()}
        {AppointmentManagementRoutes()}
        <MonitoredRoute path="/pharmacist/register">
          <EnterprisePharmacistRegistration />
        </MonitoredRoute>
        <MonitoredRoute path="/login">
          {/* Login workaround for nonSAML in LCL & SDM Dev environments */}
          {isJointLoginAvailable && (isDev || isStaging) ? (
            <SamlLogin />
          ) : (
            <Redirect to="/" />
          )}
        </MonitoredRoute>
        <MonitoredRoute path="/pharmacist">
          {isJointLoginAvailable ? <Redirect to={'/'} /> : <SamlLogin />}
        </MonitoredRoute>
        <MonitoredRoute path="/:storeNo/patient/download/:patientId/:appointmentId/:fileName">
          <DownloadDocumentation />
        </MonitoredRoute>
        <MonitoredRoute path="/:storeNo/patient/cancel/:key1/:key2/:key3">
          <CancelAppointment />
        </MonitoredRoute>
        <MonitoredRoute path="/:storeNo/patient/reschedule/:key1/:key2/:key3">
          <RescheduleAppointment />
        </MonitoredRoute>
        <MonitoredRoute path="/:storeNo/patient/covidScreening/:key1/:key2/:key3">
          <CovidScreening />
        </MonitoredRoute>
        <MonitoredRoute path="/:storeNo/patient/unsubscribe/:key1/:key2?">
          <Unsubscribe />
        </MonitoredRoute>
        <MonitoredRoute path="/:storeNo/patient/checkin/:appointmentTypeWithCalendarToken/:patientToken/:appointmentToken">
          <Checkin />
        </MonitoredRoute>
        <MonitoredRoute
          path={[
            '/:storeNo/patient/update/:key1/:key2/:key3',
            '/:storeNo/patient/bookNextAppointment/:key1/:key2/:appointmentTypeId',
            '/:storeNo/patient/:key1/:key2/:key3',
          ]}
        >
          <PatientRegistration />
        </MonitoredRoute>
        <MonitoredRoute
          path={[
            '/:storeNo/consultation/:id/:roomId',
            '/consultation/:id/:roomId',
          ]}
        >
          <ConsultationRoom />
        </MonitoredRoute>
        <MonitoredRoute path="/passwordReset">
          <ResetPassword />
        </MonitoredRoute>
        {GROUP_URL_PHARMACIST_NAME.includes(getEnterpriseCode()) && (
          <MonitoredRoute path="/:storeNo?/schedule/groups/:appointmentGroup">
            <PatientRegistration />
          </MonitoredRoute>
        )}
        <MonitoredRoute
          path={['/:storeNo?/schedule/intake/:formStep?/:patientIndex?']}
        >
          <PatientRegistration />
        </MonitoredRoute>
        <MonitoredRoute
          path={[
            '/:storeNo?/schedule/:appointmentTypeId?',
            '/:storeNo?/publicPatient/:appointmentTypeId?',
          ]}
        >
          <PatientRegistration />
        </MonitoredRoute>

        <MonitoredRoute path="/:storeNo/stayswitch/:key1/:key2/:key3">
          <StaySwitchCovidVaccine />
        </MonitoredRoute>
        <MonitoredRoute path="/:storeNo/:route/:key">
          <PatientRedirect />
        </MonitoredRoute>
        <MonitoredRoute path="/selectStore">
          <StoreSelector />
        </MonitoredRoute>
        <MonitoredRoute path="/selectLanguage">
          <SelectLanguage />
        </MonitoredRoute>
        <MonitoredRoute path="/loginLanding">
          <LoginLanding />
        </MonitoredRoute>
        <MonitoredRoute path="/loadDashboard">
          {/* Display Loading Screen if we are waiting for login to complete */}
          <Loading
            title={'Loading Pharmacist Dashboard'}
            subTitle={'Please Wait'}
            overlay
          />
        </MonitoredRoute>
        <MonitoredRoute>
          {() => {
            if (
              (isSdmEnterprise() ||
                isSdmEnterpriseJointSubdomain(window.location.hostname)) &&
              unSupportedBrowser()
            ) {
              return <UnsupportedBrowser />
            }
            if (isJointLoginAvailable) {
              setIsJointLoginRedirect(true)
              return (
                <Loading
                  title={
                    isJcgBrunet()
                      ? 'Redirecting... / Redirection...'
                      : 'Redirecting...'
                  }
                  subTitle={
                    isJcgBrunet()
                      ? 'Please Wait / Veuillez patienter'
                      : 'Please Wait'
                  }
                  overlay
                />
              )
            }
            return <SamlLogin />
          }}
        </MonitoredRoute>
      </Switch>
    )
  } else {
    // non-enterprise routes (not logged in)
    return (
      <Switch>
        {AppointmentIntakeRoutes()}
        {AppointmentManagementRoutes()}
        <MonitoredRoute path="/pharmacist/register">
          <PharmacistRegistration />
        </MonitoredRoute>
        <MonitoredRoute path="/pharmacist">
          <Login />
        </MonitoredRoute>
        <MonitoredRoute path="/patient/download/:patientId/:appointmentId/:fileName">
          <DownloadDocumentation />
        </MonitoredRoute>
        <MonitoredRoute path="/patient/cancel/:key1/:key2/:key3">
          <CancelAppointment />
        </MonitoredRoute>
        <MonitoredRoute path="/patient/reschedule/:key1/:key2/:key3">
          <RescheduleAppointment />
        </MonitoredRoute>
        <MonitoredRoute path="/patient/covidScreening/:key1/:key2/:key3">
          <CovidScreening />
        </MonitoredRoute>
        <MonitoredRoute path="/patient/unsubscribe/:key1/:key2?">
          <Unsubscribe />
        </MonitoredRoute>
        <MonitoredRoute path="/patient/checkin/:key1/:key2/:key3">
          <Checkin />
        </MonitoredRoute>
        <MonitoredRoute
          path={[
            '/patient/update/:key1/:key2/:key3',
            '/patient/bookNextAppointment/:key1/:key2/:appointmentTypeId',
            '/patient/:key1/:key2/:key3',
          ]}
        >
          <PatientRegistration />
        </MonitoredRoute>
        <MonitoredRoute path="/consultation/:id/:roomId">
          <ConsultationRoom />
        </MonitoredRoute>
        <MonitoredRoute path="/passwordReset">
          <ResetPassword />
        </MonitoredRoute>
        <MonitoredRoute
          path={[
            '/schedule/intake/:formStep?/:patientIndex?',
            '/publicPatient/intake/:formStep?/:patientIndex?',
          ]}
        >
          <PatientRegistration />
        </MonitoredRoute>
        <MonitoredRoute
          path={[
            '/schedule/groups/:appointmentGroup',
            '/schedule/:appointmentTypeId?',
            '/publicPatient/:appointmentTypeId?',
          ]}
        >
          <PatientRegistration />
        </MonitoredRoute>
        <MonitoredRoute path="/stayswitch/:key1/:key2/:key3">
          <StaySwitchCovidVaccine />
        </MonitoredRoute>
        <MonitoredRoute path="/:route/:key">
          <PatientRedirect />
        </MonitoredRoute>
        <MonitoredRoute path="/loadDashboard">
          <Loading
            title={'Loading Pharmacist Dashboard'}
            subTitle={'Please Wait'}
            overlay
          />
        </MonitoredRoute>
        {DevRoutes()}
        <MonitoredRoute>
          <Login />
        </MonitoredRoute>
      </Switch>
    )
  }
}

export default withMonitoringProfiler(App)
