import samlClientIdsPharmaprixCa from '~/config/env.pharmaprix.ca.json'
import lclSdmClientIdsStagingMedmeappCom from '~/config/env.staging.medmeapp.com.json'

const clientIds = {
  'rexalldev.medmeapp.com-clientId':
    process.env['rexalldev.medmeapp.com-clientId'],
  'rexall.medmeapp.com-clientId': process.env['rexall.medmeapp.com-clientId'],
  'saveonfoodspharmacy.medmeapp.com-clientId':
    process.env['saveonfoodspharmacy.medmeapp.com-clientId'],
  'sofdev.medmeapp.com-clientId': process.env['sofdev.medmeapp.com-clientId'],
  'sdmdev.medmeapp.com-clientId': process.env['sdmdev.medmeapp.com-clientId'],
  'sdmdevqa.medmeapp.com-clientId': process.env['sdmdev.medmeapp.com-clientId'],
  'shoppersdrugmart.medmeapp.com': process.env['shoppersdrugmart.medmeapp.com'],
  'sdmsandbox.medmeapp.com-clientId':
    process.env['sdmsandbox.medmeapp.com-clientId'],
  'sdm-sit.staging.medmeapp.com-clientId':
    lclSdmClientIdsStagingMedmeappCom.SDM_SIT_CLIENT_ID,
  'sdm-pt.staging.medmeapp.com-clientId':
    lclSdmClientIdsStagingMedmeappCom.SDM_PT_CLIENT_ID,
  'shoppersdrugmart.medmeapp.com-clientId':
    process.env['shoppersdrugmart.medmeapp.com-clientId'],
  'lcldev.medmeapp.com-clientId': process.env['lcldev.medmeapp.com-clientId'],
  'lcldevqa.medmeapp.com-clientId': process.env['lcldev.medmeapp.com-clientId'],
  'lclsandbox.medmeapp.com-clientId':
    process.env['lclsandbox.medmeapp.com-clientId'],
  'lcl-sit.staging.medmeapp.com-clientId':
    lclSdmClientIdsStagingMedmeappCom.LCL_SIT_CLIENT_ID,
  'lcl-pt.staging.medmeapp.com-clientId':
    lclSdmClientIdsStagingMedmeappCom.LCL_PT_CLIENT_ID,
  'loblawpharmacy.medmeapp.com-clientId':
    process.env['loblawpharmacy.medmeapp.com-clientId'],
  'jcgdev.medmeapp.com-clientId': process.env['jcgdev.medmeapp.com-clientId'],
  'brunetdev.medmeapp.com-clientId':
    process.env['brunetdev.medmeapp.com-clientId'],
  'jeancoutu.medmeapp.com-clientId':
    process.env['jeancoutu.medmeapp.com-clientId'],
  'brunet.medmeapp.com-clientId': process.env['brunet.medmeapp.com-clientId'],
  'consultation-dev.jeancoutu.com-clientId':
    process.env['jcgdev.medmeapp.com-clientId'],
  'consultation.jeancoutu.com-clientId':
    process.env['jeancoutu.medmeapp.com-clientId'],
  'consultation-dev.brunet.ca-clientId':
    process.env['brunetdev.medmeapp.com-clientId'],
  'consultation.brunet.ca-clientId':
    process.env['brunet.medmeapp.com-clientId'],
  'scheduler-dev.pharmaprix.ca-clientId':
    samlClientIdsPharmaprixCa.SCHEDULER_DEV_CLIENT_ID,
  'scheduler-sit.pharmaprix.ca-clientId':
    samlClientIdsPharmaprixCa.SCHEDULER_SIT_CLIENT_ID,
  'scheduler-pt.pharmaprix.ca-clientId':
    samlClientIdsPharmaprixCa.SCHEDULER_PT_CLIENT_ID,
  'scheduler.pharmaprix.ca-clientId':
    samlClientIdsPharmaprixCa.SCHEDULER_CLIENT_ID,
}

export const getClientId = (hostname) =>
  clientIds[`${hostname || window.location.hostname}-clientId`]

export default clientIds
