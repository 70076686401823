import React, { useMemo, createContext } from 'react'
import { isSdmEnterprise } from '~/util/enterpriseMethods'
import { isDev } from '~/util/envMethods'

import { postMessage, postWebViewGAEvent, WebViewAnyMessage } from './utils'

interface WebViewContextValues {
  sendEvent: (
    name: WebViewAnyMessage['event'],
    data?: Record<string, string>
  ) => void
  inWebView: boolean
}

export const WebViewContext = createContext<WebViewContextValues>(
  {} as WebViewContextValues
)

export const useWebViewContext = () => React.useContext(WebViewContext)

interface WebViewProviderProps {
  /**
   * the window.location.search string
   */
  searchString: string
}

export const WebViewProvider: React.FC<WebViewProviderProps> = ({
  children,
  searchString,
}) => {
  const inWebView = useMemo(() => {
    const params = new URLSearchParams(searchString)
    return params.has('webview') && params.get('webview') === 'true'
  }, [searchString])

  const internalSendEvent = (
    name: WebViewAnyMessage['event'],
    data: Record<string, string> = {}
  ) => {
    if (!inWebView) {
      return
    }

    if (isDev && isSdmEnterprise()) {
      console.log('WebView Event Name:', name)
      console.log('WebView Event Data:', data)
    }

    postWebViewGAEvent(name)
    postMessage({
      event: name,
      ...data,
    })
  }

  const contextValues: WebViewContextValues = {
    inWebView: inWebView,
    sendEvent: internalSendEvent,
  }

  return (
    <WebViewContext.Provider value={contextValues}>
      {children}
    </WebViewContext.Provider>
  )
}
