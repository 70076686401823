import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import MuiButton from '@material-ui/core/Button'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { TextField, Box } from '@material-ui/core'

import { red, formGray, darkGray } from '~/sharedStyles/colorPalette'

// ==============================
//             Inputs
// ==============================

export const InputContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0;
`

export const Input = styled(TextField)`
  .MuiFormLabel-root {
  }
  .MuiInputBase-input {
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const DividerWithText = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 8px 0;

  .line {
    flex-grow: 1;
  }

  .text {
    margin: 0 10px;
    white-space: nowrap;
  }
`

export const CheckboxInput = styled(Checkbox)`
  padding: 0;
  margin-right: 10px;

  &.Mui-checked {
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
`

export const RadioInput = styled(Radio)`
  &.Mui-checked {
    color: ${({ theme }) => theme.color.backgroundPrimary};
  }
`

export const DateInput = styled(KeyboardDatePicker)`
  .MuiFormLabel-root {
  }
  .MuiInputBase-root {
  }
`

export const PostalCodeInput = styled(Input)`
  & > .MuiOutlinedInput-root > .MuiInputBase-input {
    text-transform: uppercase;
  }
`

export const Button = styled(MuiButton)`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  text-transform: none;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: white;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    color: white;
    opacity: 0.4;
  }
`

export const InverseButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.color.backgroundPrimary};
  border: ${({ theme }) => `${theme.color.backgroundPrimary} solid 2px`};

  &:hover {
    background-color: white;
    opacity: 0.8;
  }

  &:disabled {
    background-color: white;
    color: ${({ theme }) => theme.color.backgroundPrimary};
    border: ${({ theme }) => `${theme.color.backgroundPrimary} solid 2px`};
    opacity: 0.4;
  }
`

export const EditButton = styled.div`
  display: flex;
  color: ${formGray};
  font-size: 0.85rem;
  font-weight: 600;
  font-family: inherit;
  cursor: pointer;
`

export const BackButton = styled(InverseButton)`
  width: 9rem;
  height: 3rem;
  margin-right: auto;
`

export const SubmitButton = styled(Button)`
  color: white;
  width: 9rem;
  height: 3rem;
`

export const ExpandSearchButton = styled(Button)`
  color: white;
  width: fit-content;
  height: 3rem;
`

// ==============================
//             Fonts
// ==============================

// H1 - Regular 40pt(page heading)
export const Heading1 = styled.div`
  font-size: 2rem;
  font-weight: 700;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

// H2 - Regular 30pt(additional patient heading, modal headings)
export const Heading2 = styled.div`
  // 30pt
  font-size: 1.25rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

// H3 - Bold 24pt(section headings – Contact Information, Medical Information, Booking Details)
export const Heading3 = styled.div`
  font-size: 1.15rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
`

export const Heading4 = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

// Body - Regular 18pt(sentences, checkbox, radio buttons, etc.)
export const Body = styled.div`
  font-size: 1rem;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  font-weight: 500;
  line-height: 1.35;
  color: ${({ theme }) => theme.color.textPrimary};
`

export const Body2 = styled(Body)`
  font-size: 0.9rem;
`

export const CardTitle = styled(Heading3)`
  font-weight: 700;
`
export const SectionTitle = styled(Body)`
  font-weight: 600;
`

// Caption - Regular 16pt(size of text box “filler text”, actual booking details)
export const Caption = styled.div`
  font-size: 0.95rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ theme }) => theme.color.textPrimary};
  line-height: 1.35;
`

export const Point = styled.div`
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  font-weight: 500;
  color: ${({ theme }) => theme.color.textTertiary};
  line-height: 1.35;
`

// ==============================
//             Labels
// ==============================

export const Title = styled(Heading3)`
  margin-bottom: 2rem;
  font-weight: 700;
`

export const SubTitle = styled(Heading4)`
  margin-bottom: 2rem;
  white-space: pre-line;
`

export const InputLabel = styled.label`
  // material ui not respecting font family nor font size em correctly, need to set it 1em to make  it look like 0.9em
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  color: ${({ color, theme }) => color || theme.font.main};
  font-size: 0.95rem;
  font-weight: 500;
`

export const ErrorText = styled(Body)`
  color: ${red};
  font-weight: 500;
  grid-column: 1 / -1;
  /* margin-bottom: 1rem; */
`

// ==============================
//             Layouts
// ==============================

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`

export const CardLayout = styled.form`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  gap: 1.5rem;
  width: 100%;
`

export const CardDivLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0;
  gap: 1.5rem;
  width: 100%;
`

export const Card = styled.div`
  /* flex: 1 0; */
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1.5rem 1rem;
  border-radius: 3px;
  background-color: white;

  @media only screen and (orientation: landscape) and (min-width: 992px) {
    padding: 1.5rem;
  }
`

export const FormStepContentLayout = styled.div`
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  gap: 1.5rem;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  & > * {
    margin-bottom: 0.5rem;
  }

  & > ${Title} {
    margin-bottom: 1rem;
  }
`

export const ReviewTitleContainer = styled.div`
  display: flex;
  flex: 0 0;
  justify-content: space-between;
  align-items: center;
  grid-column: 1 / -1;
  margin-bottom: 0;

  & > ${Title} {
    margin-bottom: 0;
  }

  @media only screen and (orientation: portrait) {
    margin-bottom: 1rem;
  }
`
export const Gray = styled.span`
  color: ${darkGray};
  font-weight: 700;
`

export const QuestionBodyContainer = styled.div`
  display: grid;
  grid-row-gap: 0.25rem;
  margin-bottom: 0.5rem;
  flex: 0.9;
  @media only screen and (orientation: landscape) and (min-width: 992px) {
    margin-bottom: 0;
  }
`

export const RadioGroupContainer = styled.div`
  display: grid;
  flex: 0.1;
  margin-top: 0.5rem;
`

export const PromoImageCard = styled(Card)`
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-radius: 1rem;

  @media only screen and (min-width: 1064px) {
    padding: 2rem;
    border-radius: 3px;
  }
`

export const PromoImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1rem;

  @media only screen and (min-width: 1064px) {
    border-radius: 3px;
  }
`
