import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useGetBookingEligibilityQuery } from '~/graphql/types/schemaNode.type'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { canFillOutDocumentation } from '~/lib/appointment/utils'

/*
  In case of Documentation Link, when appointment is still opened for intake
  we need to check if the user is eligible for the new booking flow and redirect them to the intake page if they are.
  In case documentation is completed for this appointment, we will stay in the old flow and the completion page will be shown.
*/
export function useDocumentationLink() {
  const {
    state: {
      scope,
      router: { intakeType },
      appointmentActivity,
      appointment,
      pharmacy,
    },
  } = useBookingContext()

  const isDocumentationLink = intakeType === 'documentationLink'

  const { data, loading, error } = useGetBookingEligibilityQuery({
    variables: {
      locationId: scope.locationId || pharmacy?.id,
      regionCode: scope.regionCode || pharmacy?.province,
      countryCode: scope.countryCode || 'CA',
      urlParams: [appointmentActivity.urlParam],
      method: appointment.method,
    },
    context: {
      clientName: EServer.NODE,
    },
    skip: !isDocumentationLink || !canFillOutDocumentation(appointment),
  })

  if (error) {
    throw new Error(error.message)
  }

  return {
    loading,
    isDocumentationLink,
    shouldRedirectToIntake: data?.bookingEligibility?.newBooking.isEligible,
  }
}
