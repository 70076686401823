import { useMemo } from 'react'
import { isDev, isSandbox, isStaging } from '~/util/envMethods'
import { useCurrentPharmacy } from '../useCurrentPharmacy'
import { FlagMap } from './useFeatureFlags'
import { isSdmEnterprise } from '~/util/enterpriseMethods'

type FlatStateStageType = 'dev' | 'sandbox' | 'stg' | 'prd'

function getFlagStateStage(): FlatStateStageType {
  if (isDev) {
    return 'dev'
  } else if (isSandbox) {
    return 'sandbox'
  } else if (isStaging) {
    return 'stg'
  } else {
    return 'prd'
  }
}

export const flagState: Record<string, Record<FlatStateStageType, FlagMap>> = {
  ALL: {
    dev: {
      'PS-8311': true,
      'SDM-47': true, // Admin Tools https://medmehealth.atlassian.net/browse/SDM-47
      'SDM-35': !isSdmEnterprise(),
      'SDM-191': true, // Preferred Language https://medmehealth.atlassian.net/browse/SDM-191
      'hide-co-booking-modal': isSdmEnterprise(),
      'GRAV-36': false,
      'NEB-268': true, // MSB + Group Booking https://medmehealth.atlassian.net/browse/NEB-268,
      'NEB-386': isSdmEnterprise(), // Preferred Language from AAI https://medmehealth.atlassian.net/browse/NEB-386,
      'GRAV-265': false, // TS BE calendar endpoints https://medmehealth.atlassian.net/browse/GRAV-265
      // temporary flag to help QA team check the performance in dev
      'NEB-485': isSdmEnterprise(), // NAD lazy loading https://medmehealth.atlassian.net/browse/NEB-485
      'NEB-485-mock-pharmacies': false, // Mock pharmacies for https://medmehealth.atlassian.net/browse/NEB-485
      'NEB-593': true, // Documentation Link Default https://medmehealth.atlassian.net/browse/NEB-593
      'allow-pharmacist-set-reschedule-and-cancellation-reason': true, // Show pharmacist-side reason for reschedule/cancel https://medmehealth.atlassian.net/browse/NEB-749
      'NEB-831': true, // Monitoring https://medmehealth.atlassian.net/browse/NEB-831
      'NEB-707': isSdmEnterprise(), // Claim Code https://medmehealth.atlassian.net/browse/NEB-707
    },
    sandbox: {
      'PS-8311': true,
      'SDM-47': false,
      'SDM-35': !isSdmEnterprise(),
      'SDM-191': true,
      'hide-co-booking-modal': isSdmEnterprise(),
      'GRAV-36': false,
      'NEB-268': true,
      'NEB-386': isSdmEnterprise(), // Preferred Language from AAI https://medmehealth.atlassian.net/browse/NEB-386,
      'GRAV-265': false,
      'NEB-485': false,
      'NEB-485-mock-pharmacies': false,
      'NEB-593': false,
      'allow-pharmacist-set-reschedule-and-cancellation-reason': false,
      'NEB-831': true, // Monitoring https://medmehealth.atlassian.net/browse/NEB-831
      'NEB-707': isSdmEnterprise(),
    },
    stg: {
      'PS-8311': true,
      'SDM-47': false,
      'SDM-35': !isSdmEnterprise(),
      'SDM-191': true,
      'hide-co-booking-modal': isSdmEnterprise(),
      'GRAV-36': false,
      'NEB-268': true,
      'NEB-386': isSdmEnterprise(), // Preferred Language from AAI https://medmehealth.atlassian.net/browse/NEB-386,
      'GRAV-265': false,
      'NEB-485': false,
      'NEB-485-mock-pharmacies': false,
      'NEB-593': false,
      'allow-pharmacist-set-reschedule-and-cancellation-reason': false,
      'NEB-831': true, // Monitoring https://medmehealth.atlassian.net/browse/NEB-831
      'NEB-707': isSdmEnterprise(),
    },
    prd: {
      'PS-8311': true,
      'SDM-47': false,
      'SDM-35': !isSdmEnterprise(),
      'SDM-191': true,
      'hide-co-booking-modal': isSdmEnterprise(),
      'GRAV-36': false,
      'NEB-268': true,
      'NEB-386': isSdmEnterprise(), // Preferred Language from AAI https://medmehealth.atlassian.net/browse/NEB-386,
      'GRAV-265': false,
      'NEB-485': false,
      'NEB-485-mock-pharmacies': false,
      'NEB-593': false,
      'allow-pharmacist-set-reschedule-and-cancellation-reason': false,
      'NEB-831': true, // Monitoring https://medmehealth.atlassian.net/browse/NEB-831
      'NEB-707': isSdmEnterprise(),
    },
  },
}

export const useDefaultFlagState = (): FlagMap => {
  const { enterprise } = useCurrentPharmacy()

  const stage = getFlagStateStage()

  const defaultState = useMemo(
    () =>
      flagState[enterprise] && flagState[enterprise][stage]
        ? flagState[enterprise][stage]
        : flagState.ALL && flagState.ALL[stage]
        ? flagState.ALL[stage]
        : {},
    [enterprise, stage]
  )

  return defaultState
}

export function getDefaultFlagState(flag: string): boolean {
  const stage = getFlagStateStage()

  return flagState.ALL?.[stage]?.[flag] ?? false
}

export function isLocalFeatureFlagEnabled(flag: string): boolean {
  const flagsFromStorage = JSON.parse(
    localStorage.getItem('medme-flags') || '{}'
  )
  const defaultState = getDefaultFlagState(flag)
  const isEnabled = flagsFromStorage[flag] ?? defaultState

  return isEnabled
}
