import { useMemo, useEffect } from 'react'
import {
  useAppointmentContext,
  usePatientContext,
} from '~/pages/AppointmentIntake/contexts'
import { useParams } from 'react-router-dom'
import { LanguageCodesFE } from '~/types/languageEnums'
import { getLanguageFori18n } from '~/util/lang'
import { useGetAppointmentQuery } from '~/graphql/types/schema.type'
import { isPatientUI } from '~/util/envMethods'
import 'dayjs/locale/fr'
import { useLanguage } from '../useLanguage'
import {
  LanguageLocale,
  getLanguageCodeFromLocale,
} from '~/util/languageMethods'

export const useLanguageForPatient = () => {
  const { patient } = usePatientContext()
  const { appointment } = useAppointmentContext()

  //for pharmacist side
  const { patientId, appointmentId } = useParams<{
    patientId: string
    appointmentId: string
  }>()

  const { data: appointmentData } = useGetAppointmentQuery({
    variables: {
      patientId,
      appointmentId: appointmentId ?? '',
    },
    skip: !appointmentId || !patientId,
    fetchPolicy: 'cache-first',
  })

  const { language, changeLanguage } = useLanguage()

  const languageFori18n = useMemo(() => {
    if (appointmentData?.getAppointmentV1?.languageCode) {
      return getLanguageFori18n(appointmentData?.getAppointmentV1?.languageCode)
    }

    if (appointment.id && appointment.langCode) {
      return getLanguageFori18n(appointment.langCode)
    }

    if (patient.id && patient.languageCode) {
      return getLanguageFori18n(patient.languageCode)
    }

    return getLanguageCodeFromLocale(language)
  }, [
    appointmentData?.getAppointmentV1?.languageCode,
    appointment.id,
    appointment.langCode,
    patient.id,
    patient.languageCode,
    language,
  ])

  const languageForFHIR = useMemo(() => {
    if (languageFori18n === LanguageCodesFE.FR) return LanguageLocale.FR_CA
    return LanguageLocale.EN_CA
  }, [languageFori18n])

  useEffect(() => {
    if (isPatientUI && languageForFHIR !== language) {
      changeLanguage(languageForFHIR, {
        setQueryParams: true,
      })
    }
  }, [changeLanguage, language, languageForFHIR])

  return { languageFori18n, languageForFHIR }
}
