import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { EAppointmentTypeMethod } from '~/graphql/types/schema.type'
import {
  AppointmentActivityInstance,
  createAppointmentActivityInstance,
} from '~/lib/appointmentActivity'

import { CoBookingContext } from './CoBookingContext'
import { useDocumentationLink, useNewBookingEligibility } from './hooks'
import { CoBookableAAIsDialog } from '../../components/CoBookableAAIsDialog'
import { useBookingContext as useNewBookingContext } from '../NewBookingContext'
import { useBookingContext } from '../BookingContext'
import { BookingScope } from '../../types'
import { usePharmacyContext } from '../PharmacyContext'
import { useRouterContext } from '../RouterContext'
import Loading from '~/components/loading'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { newBookingPaths } from '~/routes'
import { useRecoveryRoute } from '~/hooks/useRecoveryRoute/useRecoveryRoute'
import { AppointmentStatus } from '~/graphql/types/schemaNode.type'
import { FeatureFlag, useFeatureFlag } from '~/tools/featureGating'

interface SelectionContext {
  urlParams: string[]
  method: EAppointmentTypeMethod
  isPublic?: boolean
  scope: BookingScope
  isWaitlisted: boolean
}

export interface CoBookingContextInterface {
  coBookableAAIs: AppointmentActivityInstance[]
  coBookingHandler: (
    paths: string[],
    currentIndex: number,
    nextStep: () => void
  ) => Promise<void>
}

export interface CoBookingProviderProps {
  children: React.ReactNode | React.ReactNode[]
}

export function CoBookingProvider({ children }: CoBookingProviderProps) {
  const { t } = useMedMeTranslation()

  const {
    search,
    searchParams: { language, global },
  } = useRouterContext()

  const [openCoBookingDialog, setOpenCoBookingDialog] = React.useState(false)
  const [coBookingContext, setCoBookingContext] = React.useState<
    Pick<CoBookingContextInterface, 'coBookableAAIs'>
  >({
    coBookableAAIs: [],
  })
  const [shouldReplace, setShouldReplace] = React.useState(false)

  const [nexStepCallback, setNextStepCallback] = React.useState<() => void>(
    () => {
      /* empty */
    }
  )
  const isCoBookingDialogDisabled = useFeatureFlag(
    FeatureFlag.HIDE_CO_BOOKING_MODAL
  )

  const { getNewBookingEligibility } = useNewBookingEligibility({
    language,
    isGlobal: global,
  })

  const selectionContextRef = React.useRef<SelectionContext | undefined>()
  const [nextPath, setNextPath] = React.useState<string | undefined>()

  const history = useHistory()

  const {
    state: {
      scope,
      router: {
        appointmentTypeWithCalendarToken: key1,
        patientToken: key2,
        appointmentToken: key3,
        intakeType,
      },
      appointmentActivity,
      appointment: { method, noOfPeople, status: appointmentStatus },
    },
  } = useBookingContext()

  const { pharmacy } = usePharmacyContext()

  const { dispatch } = useNewBookingContext()

  const location = useLocation()
  const { setRecoveryRoute } = useRecoveryRoute()

  React.useEffect(() => {
    // Until eligibility knockout pages are not migrated into questionnaires
    // we need to exclude them from the recovery route for new booking flow
    if (!location.pathname.includes('/medical-screening')) {
      setRecoveryRoute(location.pathname + location.search)
    }
  }, [location, setRecoveryRoute])

  const isBookingLink = intakeType === 'bookingLink'

  selectionContextRef.current = {
    urlParams: [appointmentActivity.urlParam],
    method,
    scope,
    // When patient receives a waitlist booking link, they should be able to
    // book an appointment even if the AAI is waitlisted.
    isWaitlisted:
      appointmentActivity.isWaitlisted &&
      appointmentStatus !== AppointmentStatus.WAITLIST_LINK_SENT,
  }

  const { isDocumentationLink, shouldRedirectToIntake, loading } =
    useDocumentationLink()

  React.useEffect(() => {
    if (shouldRedirectToIntake) {
      setNextPath(newBookingPaths.intake)

      // Taking into account that we skip availability selection page
      // we need to set the pharmacy to the new booking context
      dispatch({
        type: 'setPharmacy',
        payload: pharmacy?.id ? pharmacy : undefined,
      })
    }
  }, [dispatch, pharmacy, shouldRedirectToIntake])

  const getCurrentStepEligibility = React.useCallback(
    (paths: string[], currentIndex: number) => {
      const currentPath = paths[currentIndex]

      const nextPath = paths[currentIndex + 1]
      const prevPath = paths[currentIndex - 1]

      const isNextMedicalScreening = nextPath === 'medical-screening'
      const isSingleBooking =
        currentPath === 'activity-landing' && !isNextMedicalScreening
      const isGroupBooking =
        currentPath === 'activity-group-landing' && !isNextMedicalScreening
      const isMinorAilment =
        currentPath === 'ailment-selector' && !isNextMedicalScreening

      const isMedicalScreening =
        currentPath === 'medical-screening' &&
        (prevPath === 'ailment-selector' ||
          prevPath === 'activity-group-landing' ||
          prevPath === 'activity-landing')

      return {
        isGroupBooking,
        isMinorAilment,
        isMedicalScreening,
        isSingleBooking,
      }
    },
    []
  )

  const coBookingHandler = React.useCallback(
    async (paths: string[], currentIndex: number, nextStep: () => void) => {
      const selectionContext = selectionContextRef.current

      // We skip eligibility check and redirect to availability selection page if AAI is waitlisted
      if (selectionContext?.isWaitlisted) {
        setNextPath(newBookingPaths.availability)
        return
      }

      const {
        isGroupBooking,
        isMinorAilment,
        isMedicalScreening,
        isSingleBooking,
      } = getCurrentStepEligibility(paths, currentIndex)

      if (
        isDocumentationLink ||
        !(
          isGroupBooking ||
          isMinorAilment ||
          isMedicalScreening ||
          isSingleBooking
        )
      ) {
        nextStep()
        return
      }

      if (isMedicalScreening) {
        setShouldReplace(true)
      }

      let eligibility

      // Only fetch eligibility if urlParams is not empty
      if (selectionContext && selectionContext.urlParams.length > 0) {
        try {
          eligibility = await getNewBookingEligibility({
            urlParams: selectionContext.urlParams,
            method: selectionContext.method,
            scope: selectionContext.scope,
            isPublic: selectionContext.isPublic,
          })

          const isCoBookingEligible = eligibility.coBooking.isEligible
          const isGroupBookingEligible = eligibility.groupBooking?.isEligible

          if (isCoBookingEligible) {
            const coBookableAAIs = (
              eligibility.coBooking.meta?.coBookableServices ?? []
            ).map((service) => createAppointmentActivityInstance(service))

            setCoBookingContext((prevCoBookingContext) => ({
              ...prevCoBookingContext,
              coBookableAAIs,
            }))

            if (isCoBookingDialogDisabled) {
              setNextPath(newBookingPaths.services)
              return
            }

            setOpenCoBookingDialog(true)

            if (isGroupBookingEligible) {
              // When co-booking and group booking are both eligible, we should navigate user to
              // the new booking flow even if user rejects co-booking option in dialog
              setNextStepCallback(
                () => () => setNextPath(newBookingPaths.availability)
              )
            } else {
              setNextStepCallback(() => nextStep)
            }

            return
          } else if (isGroupBookingEligible) {
            // When group booking is eligible but co-booking is not, we should navigate user to
            // the new booking flow without showing the dialog
            setNextPath(newBookingPaths.availability)
            return
          }
        } catch (error) {
          console.error(error)
        }
      }

      // Proceed to the next step if not eligible
      nextStep()
    },
    [
      getCurrentStepEligibility,
      isDocumentationLink,
      getNewBookingEligibility,
      isCoBookingDialogDisabled,
    ]
  )

  React.useEffect(() => {
    if (nextPath) {
      const searchParams = new URLSearchParams(search)

      if (key1 && key2 && key3) {
        searchParams.append('key1', key1)
        searchParams.append('key2', key2)
        searchParams.append('key3', key3)
      }

      if (isBookingLink) {
        searchParams.append('isBookingLink', 'true')
        if (noOfPeople) {
          searchParams.append('groupSize', noOfPeople.toString())
        }
      }

      if (isDocumentationLink) {
        searchParams.append('isDocumentationLink', 'true')
      }

      const initialState = {
        services: [appointmentActivity],
        scope: {
          ...scope,
          ...(pharmacy?.id ? { locationId: pharmacy?.id } : {}),
        },
        method: method,
        mainService: appointmentActivity,
      }
      dispatch({
        type: 'setInitialState',
        payload: initialState,
      })

      const newSearch = searchParams.toString() ? `?${searchParams}` : ''

      if (shouldReplace) {
        history.replace(nextPath + newSearch)
      } else {
        history.push(nextPath + newSearch)
      }
    }
  }, [
    nextPath,
    history,
    appointmentActivity,
    scope,
    pharmacy?.id,
    method,
    search,
    noOfPeople,
    dispatch,
    key1,
    key2,
    key3,
    pharmacy,
    isBookingLink,
    isDocumentationLink,
    shouldReplace,
  ])

  const value = React.useMemo(
    () => ({
      ...coBookingContext,
      coBookingHandler,
    }),
    [coBookingContext, coBookingHandler]
  )

  if (loading) return <Loading title={t('loading.title.pleaseWait')} />

  return (
    <CoBookingContext.Provider value={value}>
      {children}
      <CoBookableAAIsDialog
        coBookableAAIs={coBookingContext.coBookableAAIs}
        open={openCoBookingDialog}
        onYesButtonClick={() => {
          setOpenCoBookingDialog(false)
          setNextPath(newBookingPaths.services)
        }}
        onNoButtonClick={async () => {
          setOpenCoBookingDialog(false)
          nexStepCallback()
        }}
      />
    </CoBookingContext.Provider>
  )
}
