import { Extension } from 'fhir/r5'
import { fhirStructDefUrlMedMe } from '../constants'

const extPathName = 'item-type-variant'

export const extensionItemTypeVariantUrl =
  `${fhirStructDefUrlMedMe}/${extPathName}` as const

export const itemTypeVariantCodes = [
  'numeric',
  'radio',
  'phone',
  'email',
  'heading1',
  'heading2',
  'markdown',
  'card',
  'alphanumeric',
] as const

export interface ExtensionItemTypeVariant
  extends Pick<Extension, 'url' | 'valueCode'> {
  url: typeof extensionItemTypeVariantUrl
  valueCode: typeof itemTypeVariantCodes[number]
}
