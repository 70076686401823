import React, { ReactNode, useEffect, useReducer, useRef } from 'react'
import { EAppointmentTypeMethod } from '~/graphql/types/schemaNode.type'
import { BookingContext, BookingContextInterface } from './NewBookingContext'
import { useHistory, useLocation } from 'react-router'

const reducer = (
  state: Omit<BookingContextInterface, 'dispatch'>,
  action: { type: string; payload: any }
): Omit<BookingContextInterface, 'dispatch'> => {
  switch (action.type) {
    case 'setInitialState': {
      const { services, method, scope, mainService } = action.payload
      return {
        ...state,
        services,
        method,
        scope,
        mainService,
      }
    }
    case 'setServices':
      return {
        ...state,
        services: action.payload,
      }
    case 'addService':
      return {
        ...state,
        services: [...state.services, action.payload],
      }
    case 'setScope':
      return {
        ...state,
        scope: action.payload,
      }
    case 'setPharmacy':
      return {
        ...state,
        pharmacy: action.payload,
      }
    case 'setExternalClinic':
      return {
        ...state,
        externalClinic: action.payload,
      }
    case 'setCombinedTimeSlot':
      return {
        ...state,
        timeSlot: action.payload,
      }
    case 'setReservation':
      return {
        ...state,
        reservation: action.payload,
      }
    case 'clearReservationAndTimeSlot':
      return {
        ...state,
        reservation: undefined,
        timeSlot: undefined,
      }
    case 'setIntakeRecords':
      return {
        ...state,
        patientIntakeRecords: action.payload,
      }
    case 'updateIntakeRecord': {
      const newPatientIntakeRecordsState = state.patientIntakeRecords || []
      const updatedPatientIntakeRecord = action.payload
      const index = newPatientIntakeRecordsState.findIndex(
        (record) =>
          record.patient.patientIdx ===
          updatedPatientIntakeRecord.patient.patientIdx
      )
      if (index === -1) {
        newPatientIntakeRecordsState.push(updatedPatientIntakeRecord)
      } else {
        newPatientIntakeRecordsState[index] = {
          ...newPatientIntakeRecordsState[index],
          ...updatedPatientIntakeRecord,
        }
      }

      return {
        ...state,
        patientIntakeRecords: newPatientIntakeRecordsState,
      }
    }
    case 'setNumberOfPersons':
      return {
        ...state,
        numberOfPersons: action.payload,
      }
    case 'setClaimCode':
      return {
        ...state,
        claimCode: action.payload,
      }
    case 'updateMainService':
      return {
        ...state,
        mainService: action.payload,
      }
    default:
      return state
  }
}

export const BookingProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[]
}) => {
  const desktopRef = useRef<HTMLDivElement>(null)
  const mobileRef = useRef<HTMLDivElement>(null)

  const [context, dispatch] = useReducer(reducer, {
    services: [],
    method: EAppointmentTypeMethod.ONSITE,
    scope: {},
    numberOfPersons: 1,
    detailsWidget: {
      desktopRef,
      mobileRef,
    },
    mainService: null,
  })

  const { pathname, search } = useLocation()
  const history = useHistory()

  const searchRef = useRef<string>(search)
  searchRef.current = search

  useEffect(() => {
    if (!context.services.length) {
      return
    }

    const urlParams = context.services.map((service) => service.urlParam)
    const searchParams = new URLSearchParams(searchRef.current)

    if (searchParams.has('service')) {
      searchParams.set('service', urlParams.join(','))
    } else {
      searchParams.append('service', urlParams.join(','))
    }

    const queryString = `?${searchParams.toString().replace(/%2C/g, ',')}`
    history.replace(pathname + queryString)
  }, [context.services, history, pathname])

  return (
    <BookingContext.Provider value={{ ...context, dispatch }}>
      {children}
    </BookingContext.Provider>
  )
}
