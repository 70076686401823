import { EAppointmentTypeCategory } from '~/graphql/types/schema.type'
import { AppointmentActivityInstanceBase } from './AppointmentActivityInstance'
import { AppointmentActivityGroup } from '../appointmentActivityGroup'

export const getIsWalkinFeatureEnabled = (
  aai: AppointmentActivityInstanceBase
) => {
  if (aai.urlParam === 'quit-smoking') return false
  return true
}

export const getAaiTitleAndSubtitle = (
  aai: AppointmentActivityInstanceBase
) => {
  const title = aai.details.title
  const subtitle = aai.details.subtitle

  const aaiTitleAndSubtitle = [title, subtitle].filter(Boolean).join(' ')

  return aaiTitleAndSubtitle
}

export const getAaiFullName = (
  aai: AppointmentActivityInstanceBase,
  appointmentActivityGroup: AppointmentActivityGroup
) => {
  if (aai.category === EAppointmentTypeCategory.MINOR_AILMENT) {
    return [appointmentActivityGroup.title, getAaiTitleAndSubtitle(aai)]
      .filter(Boolean)
      .join(' - ')
  }
  return getAaiTitleAndSubtitle(aai)
}
