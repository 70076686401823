import * as React from 'react'
import { AppointmentActivityDetailsBadge } from '~/lib/appointmentActivity'
import { AaiBadgeContainer, ToolTipText } from './AaiBadge.style'
import Tooltip from '@material-ui/core/Tooltip'

export const AaiBadge: React.FC<{
  badge: AppointmentActivityDetailsBadge
}> = ({ badge }) => {
  if (!badge.visible) return null
  return (
    <Tooltip
      title={<ToolTipText>{badge.description}</ToolTipText>}
      arrow
      placement="top"
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <AaiBadgeContainer
        type="button"
        styleType={badge.style}
        onClick={(e) => e.stopPropagation()}
      >
        {badge.label}
      </AaiBadgeContainer>
    </Tooltip>
  )
}
