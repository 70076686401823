import styled from 'styled-components'
import {
  HiddenRadio,
  RadioGroup as GenericRadioGroup,
  Select as GenericSelect,
} from '~/pages/patientRegistrationV2/inputs'
import MuiButton from '@material-ui/core/Button'
import {
  red,
  paleGray,
  gray,
  primary,
  offWhite,
} from '~/sharedStyles/colorPalette'

interface Props {
  today?: boolean
  numberOfCards?: number
  disabled?: boolean
  focus?: boolean
}

export const Button = styled(MuiButton)`
  font-size: 0.75em;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  text-transform: none;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.backgroundPrimary};
  color: white;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    opacity: 0.8;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.color.backgroundPrimary};
    color: white;
    opacity: 0.4;
  }
`

// Body - Regular 18pt(sentences, checkbox, radio buttons, etc.)
export const Body = styled.div`
  font-size: 0.75em;
  font-family: ${({ theme }) => theme.font.main},
    ${({ theme }) => theme.font.generic};
  font-weight: 500;
  line-height: 1.35;
  color: ${({ theme }) => theme.color.textPrimary};
`

export const GenericErrorText = styled(Body)`
  color: ${red};
  font-weight: 500;
  grid-column: 1 / -1;
  /* margin-bottom: 1rem; */
`

export const InverseButton = styled(Button)`
  font-size: 0.75em;
  background-color: white;
  color: ${({ theme }) => theme.color.backgroundPrimary};
  border: ${({ theme }) => `${theme.color.backgroundPrimary} solid 2px`};

  &:hover {
    background-color: white;
    opacity: 0.8;
  }

  &:disabled {
    background-color: white;
    color: ${({ theme }) => theme.color.backgroundPrimary};
    border: ${({ theme }) => `${theme.color.backgroundPrimary} solid 2px`};
    opacity: 0.4;
  }
`

export const SelectContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  @media only screen and (orientation: landscape) {
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 0.5rem;
  }
`

export const Select = styled(GenericSelect)`
  align-self: flex-start;

  @media only screen and (orientation: landscape) {
    margin-left: 1rem;
    align-self: auto;
  }
`

export const SectionTitle = styled(Body)`
  font-weight: 700;
  margin-bottom: 0.5rem;

  @media only screen and (orientation: landscape) {
    margin-bottom: 0;
  }
`

export const SectionSubText = styled(Body)`
  margin-bottom: 2rem;
`

export const ItalicizedSubText = styled(Body)`
  margin-bottom: 2rem;
  font-style: italic;
  font-size: 1em;
`

export const TimeZoneText = styled(Body)`
  margin-top: 1rem;
`
export const BoldText = styled.span`
  font-weight: 700;
`

export const WarningTitle = styled(SectionTitle)`
  font-weight: 800;
  margin-top: 0.5;
`

export const RadioGroup = styled(GenericRadioGroup)`
  @media only screen and (orientation: landscape) {
    margin-top: 0.5rem;
  }
`

// Navigation
export const NavigationContainer = styled.div<Props>`
  flex: 1;
  display: flex;
  margin: 1rem 0;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  transition: opacity ease-in-out 0.2s;
`

export const NavigationButton = styled(InverseButton)`
  /* padding: 0.25rem 0.75rem; */
`

export const NavigationLeftButtonText = styled.span`
  padding-right: 0.5rem;
`

export const NavigationRightButtonText = styled.span`
  padding-left: 0.5rem;
`

export const NavigationContext = styled(Body)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${offWhite};
  border-radius: 2px;
  margin: 0 0.5rem;
  font-weight: 600;
`

// Calendar
export const CalendarHeader = styled.div<Props>`
  display: grid;
  grid-template-columns: ${({ numberOfCards }) =>
    `repeat(${numberOfCards}, 1fr)`};
  grid-column-gap: 1rem;
  transition: opacity ease-in-out 0.2s;
`

export const CalendarFooter = styled.div`
  margin-top: 1rem;
  text-align: center;
  font-size: 0.75em;
  color: ${gray};
`

export const HeaderSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 0.5rem;
  position: sticky;
  top: 0;
  background-color: white;
  border-bottom: 2px solid ${paleGray};
  transition: opacity ease-in-out 0.2s;
`

export const DateText = styled.div`
  font-size: 0.6em;
  margin-bottom: 0.25rem;
`

export const DayText = styled.div<Props>`
  font-size: 0.75em;
  font-weight: 700;
  color: ${({ theme, today }) =>
    today ? theme.color.backgroundPrimary : 'black'};
`

export const CalendarGrid = styled.div<Props>`
  position: relative;
  display: grid;
  grid-template-columns: ${({ numberOfCards }) =>
    `repeat(${numberOfCards}, 1fr)`};
  overflow-y: scroll;
  height: 100%;
  transition: opacity ease-in-out 0.2s;
  ${({ focus }) =>
    focus &&
    `
    border: 1px solid ${primary};
    border-radius: 5px;
  `}
`

export const DateColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 0 0.5rem;
  label:last-child {
    margin-bottom: 3.5rem;
  }
`

export const DateTimeCard = styled(HiddenRadio as React.FC<any>)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: ${offWhite};
  color: black;
  font-weight: 600;
  font-size: 0.65em;
  padding: 0.65rem;
  margin-bottom: 0.75rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: ${gray};
    color: white;
  }
`

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`

export const ErrorText = styled(GenericErrorText)`
  margin-top: 1rem;
`

export const EmptyCalendarOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  background-color: rgba(0, 0, 0, 0.2);
`

export const EmptyCalendarContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 3px;

  & > ${Body} {
    margin-bottom: 1rem;
    margin-top: 1rem;
    color: black;
    font-weight: 500;
  }

  & > ${Button} {
    width: fit-content;
    padding: 0.25rem 0.5rem;
  }
`

export const FadeScroller = styled.div`
  z-index: 1;
  width: 100%;
  margin-top: -100px;
  height: 100px;
  pointer-events: none;
  background: linear-gradient(
    to top,
    rgba(251, 251, 251, 1) 0%,
    rgba(251, 251, 251, 0) 100%
  );
`

export const QuestionBody = styled.ul`
  padding-inline-start: 1.25rem;
`

export const Point = styled.li`
  display: list-item;
  list-style-type: initial;
  color: ${({ theme }) => theme?.color?.textSecondary};
  font-weight: 500;
  font-size: 0.75em;
  line-height: 1.35;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`
