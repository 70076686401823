import * as React from 'react'
import Loading from '~/components/loading'

import {
  PharmacyContextInterface,
  createPharmacyContext,
  PharmacyContext,
} from '../PharmacyContext'

import { useInitialPharmacy } from './useInitialPharmacy'
import { useUpdateHeader } from './useUpdateHeader'
import { useUpdateFeatureGatingContext } from '~/tools/featureGating'

const reducer = (
  state: PharmacyContextInterface,
  action: any
): PharmacyContextInterface => {
  switch (action.type) {
    case 'initializePharmacy':
      return {
        ...state,
        initialPharmacy: action.pharmacy,
        pharmacy: action.pharmacy,
      }
    case 'setPharmacy':
      return {
        ...state,
        pharmacy: action.pharmacy,
      }
    case 'resetPharmacy':
      return {
        ...state,
        pharmacy: state.initialPharmacy,
      }
    default:
      return state
  }
}

export const PharmacyProvider: React.FC = ({ children }) => {
  const [context, dispatch] = React.useReducer(reducer, createPharmacyContext())
  const [initialized, setInitialized] = React.useState(false)

  const {
    pharmacy: initialPharmacy,
    loading: initialLoading,
    error,
  } = useInitialPharmacy()

  useUpdateFeatureGatingContext({
    pharmacy: {
      pharmacyId: initialPharmacy.id,
      province: initialPharmacy.province,
      // TODO: update queries to include country
      // https://medmehealth.atlassian.net/browse/NEB-879
      // country: initialPharmacy.country,
    },
  })

  useUpdateHeader(context.pharmacy.id ? context.pharmacy : initialPharmacy)

  React.useEffect(() => {
    if (!initialLoading) {
      dispatch({ type: 'initializePharmacy', pharmacy: initialPharmacy })
      setInitialized(true)
    }
  }, [initialLoading])

  if (!initialized) return <Loading title="Loading Pharmacy" />
  if (error) throw new Error(error)

  return (
    <PharmacyContext.Provider value={{ ...context, dispatch }}>
      {children}
    </PharmacyContext.Provider>
  )
}
