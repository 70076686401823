import { useEffect, useState } from 'react'
import { createPatient } from '~/lib/patient'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts/NewBookingContext'
import { getNextSteps } from '../../Intake/utils'

export function useInitializePatientIntakeRecords() {
  const { patientIntakeRecords, services, method, dispatch } =
    useBookingContext()
  const [isInitialized, setIsInitialized] = useState(
    patientIntakeRecords !== undefined
  )

  // Initialize empty patientIntakeRecords context
  useEffect(() => {
    if (!isInitialized) {
      dispatch({
        type: 'setIntakeRecords',
        payload: [
          {
            patient: { ...createPatient(), patientIdx: 0 },
            appointments: [],
            questionnaires: [],
            serviceTypes: services.map((service) => {
              return {
                urlParam: service.urlParam,
                details: {
                  title: service.details?.title || '',
                  nextSteps:
                    (service.details &&
                      getNextSteps(service.details, method, false)) ||
                    '',
                },
                method: method,
                locationServiceId: service.appointmentTypeId || null,
                documentTemplates: service.settings?.documentTemplates,
              }
            }),
          },
        ],
      })
      setIsInitialized(true)
    }
  }, [dispatch, isInitialized, method, patientIntakeRecords, services])

  return {
    patientIntakeRecords,
  }
}
