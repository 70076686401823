import { isSdmEnterprise, isJcgBrunet, isEnterprise } from './enterpriseMethods'

const hideHpvMerckQuestionCardDomains = [
  // 'localhost',
  'rexall.medmeapp.com',
  'rexalldev.medmeapp.com',
  'metropharmacy.medmeapp.com',
  'metrodev.medmeapp.com',
  'pollardida.medmeapp.ca',
  'cwpharmasave.medmeapp.ca',
  'westmountmedicalpharmacy.medmeapp.ca',
  'merrettspharmacy.medmeapp.ca',
  'pharmasavefortquappelle.medmeapp.com',
  'ingersollpharmasave.medmeapp.ca',
  'primecarepharmacy.medmeapp.ca',
]

export const hideHpvMerckQuestionCard = () =>
  hideHpvMerckQuestionCardDomains.includes(window.location.hostname)

export const hasSamlLogoutAccess = () => {
  if (isSdmEnterprise()) return true
  if (isJcgBrunet()) return true
  return false
}
// BOOSTER_DOSE_MODULE_CONDITIONAL
export const isBoosterEnabledUtil = true

export const getIsBillingEnabled = () => !isEnterprise()

export const storeSettingsPharmacyIds = [
  'b2a38fc8-175e-4475-ad3b-24409e064933',
  '29ecd4ee-58d0-483f-8c83-e166a86e328c',
  '5ce61e53-6c0e-47c0-975c-6e76dcdfaaf5',
  '574a49b4-33d4-4707-9e0c-67b4b24aab9e',
  '74c7b425-0d5c-4110-938e-8cb022fc0dde',
  '6d9eabdf-525e-4f02-a504-89584ba671ee',
  'e63047d0-9cae-4cfe-99c4-9b5cb5007c21',
  'dc401bae-af81-4c7d-b0ae-1a063c1788fc',
  'c8350faa-d8a4-46aa-a18c-c143d18f32af',
  '20159052-bedf-4de7-8bab-28fbf1dfd993',
  'e31ae1ed-fb21-4330-90ad-675e25e9954c',
  '53c8ef10-818d-4f78-9451-6fc00e3c0bc3',
  'f4d9f67a-189d-4324-8e34-cc2a48305dff',
  'f32feb63-7d4a-4c76-9698-48a6413c0192',
  '41ee9504-c821-4d4b-a2f0-79080cb45661',
  'ca124690-e590-4a45-9e4c-35eb7ecc950c',
  '347ad087-a6d5-4912-b6c2-8ced9ba975db',
  'b148107a-2f85-474a-965d-7894bfebb921',
  '57e07f95-d457-45dd-b302-ed096c3e2815',
  'a3b87755-95d8-4a40-a3fe-1df75f31087d',
  '8e3f0feb-e59b-468c-b233-ce33ed521830',
]

export const hasStoreSettingsAccess = (pharmacyId: string): boolean =>
  storeSettingsPharmacyIds.includes(pharmacyId)
