import { normalize } from '@sentry/utils'

const PII_VALUES = [
  // Email addresses
  /\b([A-Za-z0-9._%+-]+)@([A-Za-z0-9.-]+\.[A-Za-z]{2,})\b/gi,
  // URLs that may consist sensitive data
  /(\/|%2F)(?<route>b|c|r|s|u|un|ch)(\/|%2F)[^\s]*/i,
  /(\/|%2F)(?<route>booking(\/|%2F)reschedule|booking(\/|%2F)cancel|stayswitch)(\/|%2F)[^\s]*/i,
  /(\/|%2F)(?<route>patient)((\/|%2F)(?<extra>download|cancel|reschedule|covidScreening|unsubscribe|checkin|bookNextAppointment|update))?(\/|%2F)[^\s]*/i,
  /(\/|%2F)(?<route>consultation|medscheckPdf|ip)(\/|%2F)[^\s]*/i,
  // URL that has patient ID
  /(\/|%2F)(?<route>pharmacist(\/|%2F)patient)(\/|%2F)[^\s]*/i,
]

const PII_KEYS: RegExp[] = [
  'user',
  'username',
  'first(_|-)?name',
  'last(_|-)?name',
  'full(_|-)?name',
  'birth(_|-)?date',
  'date(_|-)?of(_|-)?Birth',
  'email',
  'address',
  'city',
  'streetNumber',
  'streetName',
  'relationship',
  'zip',
  'postalCode',
  'poBox',
  'phone',
  'home',
  'cell',
  'cellPhone',
  'homePhone',
  'fax',
  'mobile',
  'health(_|-)?card',
  'patienthealthCardNo',
  'consultationNotes',
  'password',
  'caslEmailConsent',
  'caslSmsConsent',
  'token',
  'authorization',
  'secret',
  'password',
  'passportNo',
  'passport',
  'license',
  'reason(_|-)?for(_|-)?visit',
  'patient',
].map((key) => new RegExp(key, 'i'))

const WHITE_LISTED_KEYS = ['User-Agent']

const PII_REMOVED_PLACEHOLDER = '[REMOVED]'

// Function to scrub PII data from an object
export function scrubPII({
  target,
  piiKeys = PII_KEYS,
  piiValues = PII_VALUES,
  whiteListedKeys = WHITE_LISTED_KEYS,
}: {
  target: Record<string, any>
  piiKeys?: RegExp[]
  piiValues?: RegExp[]
  whiteListedKeys?: string[]
}) {
  let clonedObj
  try {
    // clone and serialize the object, removing functions and other non JSON-conform values
    clonedObj = JSON.parse(JSON.stringify(target))
  } catch (error) {
    // if clone failed due to non-serializable types (circular reference), use sentry's
    // internal cloning tool with a performance impact
    clonedObj = normalize(target)
  }

  const stack = [clonedObj]

  while (stack.length) {
    const currentObj = stack.pop()

    keyLoop: for (const key in currentObj) {
      if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
        // If property is white-listed, we can skip further checks
        for (const whiteListedKey of whiteListedKeys) {
          if (key === whiteListedKey) {
            continue keyLoop
          }
        }

        // If property is a PII key, we should remove the value and skip further checks
        for (const regexKey of piiKeys) {
          if (regexKey.test(key)) {
            currentObj[key] = PII_REMOVED_PLACEHOLDER
            continue keyLoop
          }
        }

        // If property is a string we should check if it contains PII values
        if (typeof currentObj[key] === 'string') {
          for (const regexValue of piiValues) {
            const matches = currentObj[key].match(regexValue)

            if (!matches) {
              continue
            }

            // Named groups are used to extract the static route from the URL regex
            // and replace dynamic parts with a wildcard
            if (matches.groups) {
              const route = Object.values(matches.groups)
                .filter(Boolean)
                .reduce((acc, group) => `${acc}/${group}`, '')

              currentObj[key] = currentObj[key].replace(
                regexValue,
                `${route}/*`
              )
            } else {
              currentObj[key] = currentObj[key].replace(
                regexValue,
                PII_REMOVED_PLACEHOLDER
              )
            }
          }
        } else if (
          typeof currentObj[key] === 'object' &&
          currentObj[key] !== null
        ) {
          stack.push(currentObj[key])
        }
      }
    }
  }

  return clonedObj
}
