import styled from 'styled-components'
import {
  Heading3,
  Body,
} from '~/pages/AppointmentIntake/AppointmentIntake.style'

export const LocationInfoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  row-gap: 0.5rem;
`

export const LocationAddress = styled(Heading3)`
  font-weight: 600;
`

export const DistanceLabel = Body

export const LocationName = Body
