import { MenuItem, SvgIcon } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import {
  DropDownIcon,
  Dropdown,
  SelectedLanguage,
} from './LanguageDropdown.styles'
import LanguageIcon from '@material-ui/icons/Language'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'

import { LanguageLocale, languageTranslations } from '~/util/languageMethods'

export type LanguageSelectorTheme = 'light' | 'dark'

export type LanguageDropdownProps = {
  supportedLanguages: LanguageLocale[]
  theme?: LanguageSelectorTheme
  variant?: 'outlined'
  defaultLanguage?: LanguageLocale
  onLanguageChange: (selectedLanguage: LanguageLocale) => void
}

export const LanguageDropdown = ({
  theme = 'light',
  variant,
  supportedLanguages,
  onLanguageChange,
  defaultLanguage = LanguageLocale.EN_CA,
}: LanguageDropdownProps) => {
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(defaultLanguage)
  const handleChange = useCallback(
    (event: React.ChangeEvent<{ value: string }>) => {
      const selectedLanguage = event.target.value as LanguageLocale
      setSelectedLanguage(selectedLanguage)
      onLanguageChange(selectedLanguage)
    },
    [onLanguageChange]
  )

  return (
    <Dropdown
      theme={theme}
      variant={variant}
      defaultValue={selectedLanguage}
      value={selectedLanguage}
      onChange={handleChange}
      disableUnderline
      IconComponent={() => null}
      renderValue={(value: string) => {
        return (
          <SelectedLanguage theme={theme}>
            <SvgIcon>
              <LanguageIcon />
            </SvgIcon>
            {languageTranslations[value.toLocaleUpperCase()]}
            <DropDownIcon>
              <ArrowDropDown />
            </DropDownIcon>
          </SelectedLanguage>
        )
      }}
    >
      {supportedLanguages.map((language) => {
        return (
          <MenuItem key={language} value={language}>
            {languageTranslations[language.toLocaleUpperCase()]}
          </MenuItem>
        )
      })}
    </Dropdown>
  )
}
