import styled from 'styled-components'
import { Heading4 } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { white, gray } from '~/sharedStyles/colorPalette'

export const ToolTipText = styled(Heading4)`
  color: ${({ theme }) => theme.color.titleTertiary};
  opacity: 1;
  font-size: 0.9rem;
`

export const AaiBadgeContainer = styled.button<{
  styleType: string
}>`
  display: grid;
  place-content: center;
  background-color: ${white};
  font-size: medium;
  font-family: ${({ theme }) => theme?.font?.main},
    ${({ theme }) => theme?.font?.generic};
  font-weight: 600;
  padding: 0.25rem 1rem;
  border-radius: 30px;
  border: ${({ theme }) =>
    (props) =>
      props.styleType === 'PRIMARY'
        ? '2px solid ' + theme.color.backgroundPrimary
        : '2px solid ' + gray};
  color: ${({ theme }) =>
    (props) =>
      props.styleType === 'PRIMARY' ? theme.color.backgroundPrimary : gray};
  @media (max-width: 700px) {
    font-size: 90%;
  }
  @media (max-width: 500px) {
    font-size: 70%;
  }
`
