import React, { useCallback } from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { FormManager } from './FormManager'
import { useFormSteps, useInitializePatientIntakeRecords } from './hooks'
import { useHistory } from 'react-router-dom'
import { newBookingPaths } from '~/routes'
import Loading from '~/components/loading'
import { AppointmentIntake } from '../NewAppointmentIntake'

export const WaitlistIntake: React.FC = () => {
  const { t } = useMedMeTranslation()
  const history = useHistory()

  const { patientIntakeRecords } = useInitializePatientIntakeRecords()

  const onCompleteHandler = useCallback(() => {
    const searchParams = new URLSearchParams(history.location.search)
    searchParams.append('isWaitlist', 'true')
    history.push(`${newBookingPaths.completion}?${searchParams}`)
  }, [history])

  const steps = useFormSteps()

  return (
    <AppointmentIntake>
      {patientIntakeRecords ? (
        <FormManager steps={steps} onComplete={onCompleteHandler} />
      ) : (
        <Loading title={t('loading.title.pleaseWait')} />
      )}
    </AppointmentIntake>
  )
}
