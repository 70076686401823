import xOrganizationIds from '~/xOrganizationIds'
import { TenantMetadata } from './types'
import _ from 'lodash'
import xTenantIds from '~/xTenantIds'
import xPharmacyIds from '~/xPharmacyIds'

let tenantMatadata: TenantMetadata | null = null

export const initTenantMetadata = (metadata: TenantMetadata) => {
  // clone metadata to prevent mutation
  tenantMatadata = _.cloneDeep(metadata)
}

// TODO: remove xOrganizationIds, xTenantIds, xPharmacyIds checks
// https://medmehealth.atlassian.net/browse/NEB-1115

export const getOrganizationId = () => {
  if (!tenantMatadata || 'error' in tenantMatadata) {
    return xOrganizationIds[window.location.hostname] ?? null
  }

  return tenantMatadata.organizationId
}

export const getTenantId = () => {
  if (!tenantMatadata || 'error' in tenantMatadata) {
    return xTenantIds[window.location.hostname] ?? null
  }

  return tenantMatadata.tenantId
}

/**
 *
 * @param checkLocalStorage Whether to check localStorage for pharmacyId
 * @returns pharmacyId or null
 */
export const getPharmacyId = (checkLocalStorage: boolean = false) => {
  if (checkLocalStorage && localStorage.getItem('pharmacyId')) {
    return localStorage.getItem('pharmacyId')
  }

  if (!tenantMatadata || 'error' in tenantMatadata) {
    return xPharmacyIds[`${window.location.hostname}-pharmacyId`] ?? null
  }

  return tenantMatadata.pharmacyId
}
