import React, { useContext, useMemo } from 'react'
import { usePharmacySupportedLanguages } from '~/hooks/pharmacySupportedLanguages/usePharmacySupportedLanguages'
import { usePublicGetEnterprisePharmacyQuery } from '~/graphql/types/schema.type'
import {
  getEnterpriseCode,
  isEnterprise,
  isLCLRedirect,
  isLcl,
  isMinorEnterprise,
} from '~/util/enterpriseMethods'
import { LanguageDropdown } from '../LanguageDropdown'
import { useLanguage } from '~/hooks/useLanguage'
import { isDarkColor } from '~/util/mui.theme'
import { ThemeContext } from 'styled-components'
import { LanguageLocale } from '~/util/languageMethods'

export type PatientLanguageSelectorProps = {
  pharmacyId?: string
}

export const PatientLanguageSelector = (
  props: PatientLanguageSelectorProps
) => {
  const { color } = useContext(ThemeContext)
  const languageSelectorTheme = useMemo(() => {
    if (
      isLCLRedirect() ||
      (isEnterprise() && !(isLcl() || isMinorEnterprise()))
    ) {
      return isDarkColor(color?.backgroundPrimary) ? 'light' : 'dark'
    }

    return 'dark' // background is white
  }, [color?.backgroundPrimary])

  const { data: initialEnterprisePharmacy } =
    usePublicGetEnterprisePharmacyQuery({
      variables: {
        enterpriseName: getEnterpriseCode(),
      },
      skip: !isEnterprise() || Boolean(props.pharmacyId), // skip if not enterprise or if pharmacyId is not empty
    })

  const { supportedLanguages } = usePharmacySupportedLanguages(
    props.pharmacyId || initialEnterprisePharmacy?.getEnterprisePharmacy?.id
  )

  const supportedLanguageLocales = supportedLanguages
    .map((lang) => lang.locale)
    .filter(Boolean) as LanguageLocale[]

  const { language, changeLanguage } = useLanguage(supportedLanguageLocales, {
    setQueryParams: true,
    setLocalStorageOverride: true,
    refetchQueries: 'active',
  })

  if (supportedLanguageLocales.length === 0) {
    return null
  }

  return (
    <LanguageDropdown
      theme={languageSelectorTheme}
      supportedLanguages={supportedLanguageLocales}
      defaultLanguage={language}
      onLanguageChange={changeLanguage}
    />
  )
}
