import * as React from 'react'
import { Pharmacy } from '~/lib/pharmacy'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { NoOfPeople } from './NoOfPeople'
import { SelectDateTimeText } from './SelectDateTimeText'

export const SelectionHeader: React.FC<{
  pharmacy: Pharmacy
  isSingleBookingOnly: boolean
  maxNoOfPeople: number
}> = ({ pharmacy, isSingleBookingOnly, maxNoOfPeople }) => {
  const {
    state: {
      appointmentActivity: { urlParam },
    },
  } = useBookingContext()
  if (isSingleBookingOnly) {
    if (urlParam === 'paxlovid') {
      return (
        <SelectDateTimeText
          prefix="IMPORTANT: COVID-19 antiviral treatment (Paxlovid) must be taken within 5 days of symptoms starting."
          pharmacy={pharmacy}
        />
      )
    }
    return <SelectDateTimeText pharmacy={pharmacy} />
  }

  return (
    <>
      <NoOfPeople titlePrefix="1. " maxNoOfPeople={maxNoOfPeople} />
      <SelectDateTimeText titlePrefix="2. " pharmacy={pharmacy} />
    </>
  )
}
