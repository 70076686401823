import { EnterpriseEnums } from '~/shared/constants/enterpriseConstants'
import { LanguageCodesBE, LanguageCodesFE } from '~/types/languageEnums'
import { logErrorInDev } from './customLoggers'

type LanguageCodeUnparsed = 'en' | 'EN' | 'fr' | 'FR' | string

export const LANGUAGE_QUERY_PARAM = 'language'
export const LANGUAGE_OVERRIDE_LOCAL_STORAGE_KEY = 'languageOverride'

export enum LanguageLocale {
  EN_CA = 'en-CA',
  FR_CA = 'fr-CA',
}

export enum LanguageCode {
  EN = 'en',
  FR = 'fr',
}

export const DEFAULT_LANGUAGES: LanguageLocale[] = [
  LanguageLocale.EN_CA,
  LanguageLocale.FR_CA,
]

export function getLanguageCodeFromLocale(locale: string): string {
  return locale.slice(0, 2).toLowerCase()
}

export function getLocaleFromLanguageCode(code: string): LanguageLocale {
  switch (code?.toLowerCase()) {
    case LanguageCode.EN:
      return LanguageLocale.EN_CA
    case LanguageCode.FR:
      return LanguageLocale.FR_CA
    default:
      return LanguageLocale.EN_CA
  }
}

// TODO: replace or deprecate legacy methods
// https://medmehealth.atlassian.net/browse/NEB-1155

// For better UX, language should be translated to its corresponding language
export const languageTranslations: {
  [key: string]: string
} = {
  ['EN-CA']: 'English',
  ['FR-CA']: 'Français',
}

export const displayFrenchText = [EnterpriseEnums.JCG, EnterpriseEnums.BRUNET]

export const languages = [
  { lang: 'English', code: LanguageCodesFE.EN },
  { lang: 'Francais', code: LanguageCodesFE.FR },
]

export const LANGUAGE_RADIO_OPTIONS_BE = [
  {
    value: LanguageCodesBE.EN,
    label: 'English',
  },
  {
    value: LanguageCodesBE.FR,
    label: 'French',
  },
]

export const LANGUAGE_RADIO_OPTIONS_BE_FR_EN = [
  {
    value: LanguageCodesBE.EN,
    label: 'English',
  },
  {
    value: LanguageCodesBE.FR,
    label: 'Français',
  },
]

export const LANGUAGE_RADIO_OPTIONS_BE_FR = [
  {
    value: LanguageCodesBE.EN,
    label: 'Anglais',
  },
  {
    value: LanguageCodesBE.FR,
    label: 'Français',
  },
]

export const getLanguageCodesForFrontEnd = (
  languageCode: LanguageCodeUnparsed
) => {
  if (
    languageCode === 'en' ||
    languageCode === 'EN' ||
    languageCode == LanguageLocale.EN_CA
  )
    return LanguageCodesFE.EN
  if (
    languageCode === 'fr' ||
    languageCode === 'FR' ||
    languageCode == LanguageLocale.FR_CA
  )
    return LanguageCodesFE.FR

  logErrorInDev(`unsupported language code ${languageCode}`)
  return LanguageCodesFE.EN
}

export const getLanguageLocaleForFrontEnd = (
  languageCode: string
): LanguageLocale | undefined => {
  if (languageCode === LanguageLocale.FR_CA) return LanguageLocale.FR_CA
  if (languageCode === LanguageLocale.EN_CA) return LanguageLocale.EN_CA

  logErrorInDev(`unsupported language code ${languageCode}`)
  return undefined
}

export const getLanguageCodesForBackEnd = (
  languageCode: LanguageCodeUnparsed
) => {
  if (
    languageCode === 'en' ||
    languageCode === 'EN' ||
    languageCode === LanguageLocale.EN_CA
  )
    return LanguageCodesBE.EN
  if (
    languageCode === 'fr' ||
    languageCode === 'FR' ||
    languageCode === LanguageLocale.FR_CA
  )
    return LanguageCodesBE.FR

  logErrorInDev(`unsupported language code: ${languageCode}`)
  return LanguageCodesBE.EN
}

/**
 * fallback to English if language code is not supported
 * @param {LanguageCodesBE} languageCode
 * @returns {LanguageLocale}
 */
export const getLocaleBasedLanguageCode = (
  languageCode: LanguageCodesBE
): LanguageLocale => {
  if (languageCode === LanguageCodesBE.FR) return LanguageLocale.FR_CA
  if (languageCode === LanguageCodesBE.EN) return LanguageLocale.EN_CA

  logErrorInDev(`unsupported language code: ${languageCode}`)
  return LanguageLocale.EN_CA
}
