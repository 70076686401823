import * as React from 'react'
import { createPharmacy } from '~/lib/pharmacy'

export const createPharmacyContext = () => ({
  initialPharmacy: createPharmacy(),
  pharmacy: createPharmacy(),
  dispatch: (() => undefined) as React.Dispatch<any>,
})

export type PharmacyContextInterface = ReturnType<typeof createPharmacyContext>

export const PharmacyContext = React.createContext(createPharmacyContext())
