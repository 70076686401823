import * as React from 'react'

import { useGetBookingEligibilityLazyQuery } from '~/graphql/types/schemaNode.type'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { EAppointmentTypeMethod } from '~/graphql/types/schema.type'
import { BookingScope } from '~/pages/AppointmentIntake/types'
import { getPharmacyId } from '~/lib/tenantMetadata'

interface IGetNewBookingEligibility {
  language?: string
  isGlobal?: boolean
}

export function useNewBookingEligibility({
  language,
  isGlobal,
}: IGetNewBookingEligibility) {
  const [fetchEligibility, { data, error, loading, client }] =
    useGetBookingEligibilityLazyQuery({
      context: {
        clientName: EServer.NODE,
        headers: {
          'accept-language': language || 'en-CA',
          'x-pharmacyid': isGlobal ? '' : getPharmacyId(true) ?? '',
        },
      },
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
    })

  const getNewBookingEligibility = React.useCallback(
    async ({
      urlParams,
      method,
      isPublic,
      scope,
    }: {
      urlParams: string[]
      method: EAppointmentTypeMethod
      isPublic?: boolean
      scope: BookingScope
    }) => {
      const { data, error } = await fetchEligibility({
        variables: {
          ...scope,
          urlParams,
          method,
          isPublic,
        },
      })

      if (error) {
        throw new Error(error.message)
      }

      return (
        data?.bookingEligibility ?? {
          groupBooking: {
            isEligible: false,
          },
          coBooking: {
            isEligible: false,
          },
          newBooking: {
            isEligible: false,
          },
        }
      )
    },
    [fetchEligibility]
  )

  return {
    getNewBookingEligibility,
    data,
    loading,
    error,
    client,
  }
}
