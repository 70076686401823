import pharmaprixEnv from '~/config/env.pharmaprix.ca.json'
/**
 * ⚠️ TEMPORARY This file is a temporary placeholder for organization IDs.
 */

// TODO: Replace scheduler staging and sandbox with sit/pt
export default {
  'phx.medmeapp.local': pharmaprixEnv.SCHEDULER_LOCAL_ORG_ID,
  'scheduler-dev.pharmaprix.ca': pharmaprixEnv.SCHEDULER_DEV_ORG_ID,
  'scheduler-sandbox.pharmaprix.ca': pharmaprixEnv.SCHEDULER_SANDBOX_ORG_ID,
  'scheduler-sit.pharmaprix.ca': pharmaprixEnv.SCHEDULER_SIT_ORG_ID,
  'scheduler-pt.pharmaprix.ca': pharmaprixEnv.SCHEDULER_PT_ORG_ID,
  'scheduler.pharmaprix.ca': pharmaprixEnv.SCHEDULER_ORG_ID,
} as { [key: string]: string | undefined }
