import { FormControl, FormControlLabel, Typography } from '@material-ui/core'
import * as React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { InputLabel } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { RadioGroup, Radio } from './IsWalkinRadio.style'
import { useTimeSlotSelectionStyles } from '~/pages/AppointmentIntake/NewBooking/AvailabilitySelection/LocationList/TimeSlotSelection/shared.style'

export const IsWalkinRadio: React.FC = () => {
  const { t } = useMedMeTranslation('patient')
  const { control } = useFormContext()
  const {
    field: { ref, ...fields },
  } = useController({
    name: 'isWalkin',
    control: control,
    defaultValue: 'false',
  })
  const {
    state: {
      appointment: { method },
    },
  } = useBookingContext()

  const classes = useTimeSlotSelectionStyles()
  const walkInText = (
    <Typography>
      <Trans
        t={t}
        i18nKey="walkinBookingText"
        components={{
          strong: <Typography component="span" className={classes.bold} />,
        }}
      />
    </Typography>
  )
  const showWalkinRadio = method === 'ONSITE'
  const nonWalkinText = t('selectDateAndTimeBelow')

  return (
    <FormControl component="fieldset" {...fields}>
      <RadioGroup {...fields}>
        <FormControlLabel
          value="false"
          control={<Radio />}
          label={<InputLabel>{nonWalkinText}</InputLabel>}
        />
        {showWalkinRadio && (
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={<InputLabel>{walkInText}</InputLabel>}
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}
