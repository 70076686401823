import React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

import { AppointmentActivityInstance } from '~/lib/appointmentActivity'

import DialogContent from '@material-ui/core/DialogContent'

import {
  StyledTitle,
  StyledCoBookableAAIsNames,
  StyledDialog,
  StyledDialogActions,
  StyledFooterText,
} from './CoBookableAAIsDialog.styles'
import { Typography } from '@material-ui/core'
import { StyledButton } from '../shared.styles'

export type CoBookableAAIsDialogProps = {
  coBookableAAIs: AppointmentActivityInstance[]
  open: boolean
  onYesButtonClick: () => void
  onNoButtonClick: () => void
}

export function CoBookableAAIsDialog({
  coBookableAAIs,
  open,
  onYesButtonClick,
  onNoButtonClick,
}: CoBookableAAIsDialogProps) {
  const { t } = useMedMeTranslation('patient')

  const onYesButtonClickHandler = () => {
    onYesButtonClick()
  }

  const onNoButtonClickHandler = () => {
    onNoButtonClick()
  }

  return (
    <StyledDialog open={open}>
      <StyledTitle>{t('coBookableAAIsDialog.title')}</StyledTitle>
      <DialogContent>
        <Typography variant="body2" color="textPrimary" paragraph={false}>
          {t('coBookableAAIsDialog.header')}
        </Typography>
        {coBookableAAIs.map((aai) => (
          <StyledCoBookableAAIsNames
            key={aai.id}
            variant="body2"
            color="textPrimary"
          >
            {aai.details?.title || aai.name}
          </StyledCoBookableAAIsNames>
        ))}
        <StyledFooterText variant="body2" color="textPrimary">
          {t('coBookableAAIsDialog.footer')}
        </StyledFooterText>
      </DialogContent>
      <StyledDialogActions disableSpacing>
        <StyledButton
          variant="outlined"
          onClick={onNoButtonClickHandler}
          data-cy="noCoBookingButton"
          autoFocus
        >
          {t('coBookableAAIsDialog.noButton')}
        </StyledButton>
        <StyledButton
          variant="contained"
          color="primary"
          data-cy="yesCoBookingButton"
          onClick={onYesButtonClickHandler}
        >
          {t('coBookableAAIsDialog.yesButton')}
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  )
}
