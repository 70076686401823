import {
  isMetroPharmacyProdSubdomain,
  isMintDrugsProdSubdomain,
  isRexallProdSubdomain,
  isSdmEnterprise,
  isSdmEnterpriseDevSubdomain,
  isSdmEnterpriseProdSubdomain,
  isSofProdSubdomain,
} from '~/util/enterpriseMethods'

export const initializeGTM = () => {
  const dataLayerKey = () => 'dataLayer'
  const dataLayerIdOld = () => 'GTM-KRPKZZ6'
  const dataLayerIdForSdmLclProd = () => 'G-S32JRDG1D6'
  const dataLayerIdForSdmLclDev = () => 'G-MGTH2E0LYQ'

  const dataLayerIdForRexallProd = () => 'G-RTNH6NSDX3'
  const dataLayerIdForSofProd = () => 'G-32JKJJS4QE'
  const dataLayerIdForMetroProd = () => 'G-3MF5EPEYFH'
  const dataLayerIdForMintDrugsProd = () => 'G-Y6EB9K0B7D'

  const when = (
    condition: () => boolean,
    fn: (id: string) => void,
    getDataLayerId: () => string
  ) => {
    if (condition()) fn(getDataLayerId())
  }

  const initialDataLayer = () => {
    return window.dataLayer ?? []
  }

  const defaultEvent = () => {
    return {
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    }
  }

  const currentFirstScriptElement = () =>
    window.document.getElementsByTagName('script')[0]

  const gtmParams = (dataLayerKey: string, defaultDataLayerKey: string) =>
    dataLayerKey !== defaultDataLayerKey ? '&l=' + dataLayerKey : ''

  const gtmScript = (id: string, params: string) => {
    const script = window.document.createElement('script')
    script.async = true
    script.src = 'https://www.googletagmanager.com/gtm.js?id=' + id + params
    return script
  }

  const insertBeforeElement = (node: Element, newNode: Element): void => {
    const parentNode = node?.parentNode
    if (parentNode) parentNode.insertBefore(newNode, node)
    else console.error('googleTagManager: parentNode is undefined')
  }

  const appendToDataLayer = (
    dataLayer: Window['dataLayer'],
    event: Record<string, any>
  ) => {
    if (dataLayer === undefined) return dataLayer
    return dataLayer.length > 0 ? [...dataLayer, event] : [event]
  }

  const setDataLayer = (dataLayer: Window['dataLayer']) => {
    window.dataLayer = dataLayer
  }

  const isPublicAppointmentBookingURL = (url: string) =>
    /^(\/lcl)?(\/[(0-9)]+)?(\/public|\/schedule).*/.test(url)

  const initialize = (dataLayerId: string) => {
    const dataLayer = appendToDataLayer(initialDataLayer(), defaultEvent())
    setDataLayer(dataLayer)

    const script = gtmScript(
      dataLayerId,
      gtmParams(dataLayerKey(), dataLayerKey())
    )
    insertBeforeElement(currentFirstScriptElement(), script)
  }

  when(
    () =>
      isSdmEnterprise() &&
      /^(\/lcl)?(\/[(0-9)]+)?(\/patient|\/schedule).*/.test(location.pathname),
    initialize,
    dataLayerIdOld
  )

  // analytics for /schedule and /public traffic
  when(
    () =>
      isSdmEnterpriseProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForSdmLclProd
  )

  when(
    () =>
      isSdmEnterpriseDevSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForSdmLclDev
  )

  when(
    () =>
      isRexallProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForRexallProd
  )

  when(
    () =>
      isSofProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForSofProd
  )

  when(
    () =>
      isMetroPharmacyProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForMetroProd
  )

  when(
    () =>
      isMintDrugsProdSubdomain(window.location.hostname) &&
      isPublicAppointmentBookingURL(location.pathname),
    initialize,
    dataLayerIdForMintDrugsProd
  )
}
