import { DateFormat } from './types'
import dayjs from 'dayjs'

/**
 * @description Formats a date string in UTC to a localized date string
 *
 * @example getLocalizedDateFormat('2021-02-01T00:00:00Z', DateFormat.Date, 'America/New_York')
 *
 * @param utcDateString Date string in UTC
 * @param format Format of the date
 * @param timezone Timezone to convert the date to
 * @returns Formatted date string
 */
export const getLocalizedDateFormat = (
  utcDateString: string,
  format: DateFormat,
  timezone?: string
): string => {
  let date = dayjs.utc(utcDateString)

  if (timezone) {
    date = date.tz(timezone)
  }

  switch (format) {
    case DateFormat.Time:
      return date.format('LT')
    case DateFormat.TimeCompact:
      return date.format('LT').replace(' AM', 'am').replace(' PM', 'pm')
    case DateFormat.TimeWithSeconds:
      return date.format('LTS')
    case DateFormat.NumberedDate:
      return date.format('L')
    case DateFormat.Date:
      return date.format('LL')
    case DateFormat.DateTime:
      return date.format('LLL')
    case DateFormat.DayDateTime:
      return date.format('LLLL')
    case DateFormat.AbbrDate:
      return date.format('ll')
    case DateFormat.AbbrDateWithoutYear:
      return date.format('ll').replace(/,? \d{4}$/, '')
    case DateFormat.AbbrDateTime:
      return date.format('lll')
    case DateFormat.AbbrDayDate:
      return date.format('ddd, ll')
    case DateFormat.AbbrDayDateTime:
      return date.format('llll')
    case DateFormat.TimeAndAbbrDate:
      return date.format('LT, ll')
    case DateFormat.AbbrDayDateWithoutYear:
      return date.format('ddd ll').replace(/,? \d{4}$/, '')
  }

  throw new Error('Invalid date format')
}
