export enum EnterpriseEnums {
  REXALL = 'REXALL',
  SOF = 'SOF',
  METRO = 'METRO',
  HEART = 'HEART',
  NWC = 'NWC',
  PANS = 'PANS',
  LIFE_SMART = 'LIFE_SMART',
  SDM = 'SDM',
  LCL = 'LCL',
  PHX = 'PHX',
  JCG = 'JCG',
  BRUNET = 'BRUNET',
  MEDME = 'MEDME',
  MINT = 'MINT',
}
