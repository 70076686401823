import React, { useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { BulletContainer, NADContainer } from './NextAvailableDay.styles'
import { DateFormat, getLocalizedDateFormat } from '~/util/date'
import { MAX_NAD_FETCH_WINDOW_SIZE_DAYS } from '../../NewBooking/FilteredAvailabilitySelection/FilteredAvailabilitySelection.constants'

dayjs.extend(utc)
dayjs.extend(timezone)

type NextAvailableDayProps = {
  date: string | null | undefined
  timeZone: string
}

export const NextAvailableDay: React.FC<NextAvailableDayProps> = ({
  date,
  timeZone,
}) => {
  const { t } = useMedMeTranslation('patient')

  const nextAvailableDay = useMemo(() => {
    if (date === null || date === undefined) return date

    const fetchedDate = dayjs.utc(date)

    const today = dayjs().utc().startOf('day')
    const tomorrow = today.add(1, 'day')

    if (
      fetchedDate.isSame(today, 'day') &&
      fetchedDate.isSame(today, 'month')
    ) {
      return t('nextAvailableDay.today')
    }
    if (
      fetchedDate.isSame(tomorrow, 'day') &&
      fetchedDate.isSame(tomorrow, 'month')
    ) {
      return t('nextAvailableDay.tomorrow')
    }

    const formattedDate = getLocalizedDateFormat(
      date,
      DateFormat.AbbrDayDateWithoutYear,
      timeZone
    )
    if (formattedDate == 'Invalid Date') return undefined

    return formattedDate
  }, [date, t, timeZone])

  const nextAvailableTime = useMemo(() => {
    if (!date) return ''
    const dateInTimezone = dayjs.utc(date).tz(timeZone)
    const minute = dateInTimezone.get('minute')

    const roundedMinute = Math.ceil(minute / 5) * 5
    const roundedDate = dateInTimezone.set('minute', roundedMinute)

    const formattedTime = getLocalizedDateFormat(
      roundedDate.utc().format(),
      DateFormat.Time,
      timeZone
    )
    if (formattedTime == 'Invalid Date') return undefined
    return formattedTime
  }, [date, timeZone])

  return (
    <NADContainer display="flex" alignItems="center">
      {nextAvailableDay ? (
        <>
          <Typography variant="body2" style={{ marginRight: '0.5rem' }}>
            {t('nextAvailableDay.nextAvailable')}
          </Typography>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {nextAvailableDay}
          </Typography>
        </>
      ) : (
        <Typography variant="body2">
          {t('nextAvailableDay.noAvailability', {
            count: MAX_NAD_FETCH_WINDOW_SIZE_DAYS,
          })}
        </Typography>
      )}

      {nextAvailableTime ? (
        <>
          <BulletContainer variant="body1" style={{ fontWeight: 'bold' }}>
            {' • '}
          </BulletContainer>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            {nextAvailableTime}
          </Typography>
        </>
      ) : null}
    </NADContainer>
  )
}
