import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { LoadingSkeleton } from '~/componentsTs/LoadingSkeleton'
import { gray } from '~/sharedStyles/colorPalette'

export const BulletContainer = styled(Typography)`
  color: ${gray};
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`

export const NADContainer = styled(Box)`
  white-space: nowrap;
  width: 100%;
`

export const NADLoadingSkeleton = styled(LoadingSkeleton)`
  border-radius: 0.95rem;
  margin-top: -0.1rem;

  @media (min-width: 401px) {
    width: 4.75rem;
  }
`
