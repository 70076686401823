import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { Pharmacy } from '~/lib/pharmacy'
import { Body } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { SectionTitle, BoldText, Container } from './SelectionHeader.style'

export const SelectDateTimeText: React.FC<{
  prefix?: string
  titlePrefix?: string
  pharmacy: Pharmacy
}> = ({ prefix, titlePrefix, pharmacy }) => {
  const { t } = useMedMeTranslation('patient')

  const title = t('selectADateAndTime')
  const text = t('pharmacyLocatedInTz')

  return (
    <Container>
      {prefix && <Body>{prefix}</Body>}
      <SectionTitle>
        {titlePrefix}
        {title}
      </SectionTitle>
      <Body>
        {text}
        <BoldText>{pharmacy.timeZone}</BoldText>
      </Body>
    </Container>
  )
}
