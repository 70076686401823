import { Box, Select, SvgIcon } from '@material-ui/core'
import styled from 'styled-components'
import { LanguageSelectorTheme } from './LanguageDropdown'

export const Dropdown = styled(Select)<{
  theme: LanguageSelectorTheme
  variant?: 'outlined'
}>`
  color: ${(props) => (props.theme === 'light' ? 'white' : 'black')};
  font-weight: lighter;
  border-radius: 5px;
  .MuiSelect-select.MuiSelect-select {
    padding: 0.5rem;
  }
  border: ${(props) => (props.variant === 'outlined' ? '1px solid' : 'none')};
  border-color: ${(props) => (props.theme === 'light' ? 'white' : 'black')};
`

export const SelectedLanguage = styled(Box)<{ theme: LanguageSelectorTheme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  svg {
    color: ${(props) => (props.theme === 'light' ? 'white' : 'black')};
  }
`

export const DropDownIcon = styled(SvgIcon)`
  margin-left: -0.6rem;
`
