import React from 'react'
import { LayoutWrapper } from '~/componentsTs/PatientSideLayout'

import { usePharmacyContext, useWhiteLabelContext } from '../../contexts'
import {
  Body,
  PageContainer,
  SideBar,
  SideContainer,
} from './AppointmentIntake.styles'
import { useBookingContext } from '../../contexts/NewBookingContext'
import { DetailsWidgetDesktop, DetailsWidgetMobile } from '../../components'
import { useRouteMatch } from 'react-router-dom'
import { newBookingPaths } from '~/routes'
import { useRecoveryRoute } from '~/hooks/useRecoveryRoute/useRecoveryRoute'

interface AppointmentIntakeProps {
  children: React.ReactNode | React.ReactNode[]
  sideWidget?: React.ReactNode
}

export function AppointmentIntake({
  children,
  sideWidget,
}: AppointmentIntakeProps) {
  const { pharmacy: initialPharmacy } = usePharmacyContext()
  const { whiteLabel, loading: isWhiteLabelLoading } = useWhiteLabelContext()
  const {
    pharmacy,
    externalClinic,
    services,
    numberOfPersons,
    timeSlot,
    detailsWidget,
  } = useBookingContext()
  const matchLastStep = useRouteMatch(newBookingPaths.completion)

  useRecoveryRoute({
    condition: services.length === 0,
  })

  return (
    <LayoutWrapper
      whiteLabel={whiteLabel}
      isWhiteLabelLoading={isWhiteLabelLoading}
      pharmacy={initialPharmacy}
      isLastForm={matchLastStep !== null}
    >
      <Body>
        <DetailsWidgetMobile
          mobileRef={detailsWidget.mobileRef}
          pharmacy={pharmacy}
          services={services}
          numberOfPersons={numberOfPersons}
          timeSlot={timeSlot}
        />
        <SideContainer>
          <SideBar>
            <DetailsWidgetDesktop
              desktopRef={detailsWidget.desktopRef}
              pharmacy={pharmacy}
              externalClinic={externalClinic}
              services={services}
              numberOfPersons={numberOfPersons}
              timeSlot={timeSlot}
            />
            {sideWidget}
          </SideBar>
        </SideContainer>
        <PageContainer>{children}</PageContainer>
      </Body>
    </LayoutWrapper>
  )
}
