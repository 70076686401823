//import React from 'react'
import { EServer } from '~/graphql/hooks/useGraphQL/links'
import { useFindSublocationByIdQuery } from '~/graphql/types/schemaNode.type'
import { getSublocationIdFromSearchParams } from '~/util/getSublocationIdFromSearchParams'

export const useFindSublocationById = () => {
  const sublocationId = getSublocationIdFromSearchParams()

  const { data, loading: isSublocationLoading } = useFindSublocationByIdQuery({
    variables: { id: sublocationId ?? '' },
    context: {
      clientName: EServer.NODE,
    },
    skip: !sublocationId,
  })

  const sublocationData = data?.findSublocationById

  return {
    sublocationData,
    isSublocationLoading,
  }
}
