import { ApolloCache, gql } from '@apollo/client'
import { Store } from 'redux'

// util
import {
  getCurrentCognitoUser,
  formatRefreshToken,
} from '~/auth/awsCognitoMethods'
import {
  getIsSamlLogin,
  getRefreshToken,
  refreshWithAxios,
  setAccessToken,
  setIdToken,
} from './auth'
import { reportException } from '~/tools/monitoring'
import { AuthError } from './types'

export const updateClientQueryParams = (
  isLoggedIn: boolean,
  isAdmin: boolean,
  isPharmacyStaffReadOnly: boolean,
  isSamlLogin: boolean
) => ({
  query: gql`
    {
      isAdmin
      isLoggedIn
      isPharmacyStaffReadOnly
      isSamlLogin
    }
  `,
  data: {
    isLoggedIn,
    isAdmin,
    isPharmacyStaffReadOnly,
    isSamlLogin,
  },
})

export const updateClientQueryParamsWithObj = ({
  isLoggedIn,
  isAdmin,
  isPharmacyStaffReadOnly,
  isSamlLogin,
}: {
  isLoggedIn: boolean
  isAdmin: boolean
  isPharmacyStaffReadOnly: boolean
  isSamlLogin: boolean
}) =>
  updateClientQueryParams(
    isLoggedIn,
    isAdmin,
    isPharmacyStaffReadOnly,
    isSamlLogin
  )

export const selectiveClearLocalStorage = (
  // we don't want to clear the redux-persisted dashboard state
  keysToExclude: string[] = ['persist:dashboard', 'aiSearchConsent']
) => {
  const keys = Object.keys(localStorage)

  for (const key of keys) {
    if (!keysToExclude.includes(key)) localStorage.removeItem(key)
  }
}

export const logout = (cache: ApolloCache<any>, store: Store) => {
  selectiveClearLocalStorage()
  cache.writeQuery(updateClientQueryParams(false, false, false, false))
  store.dispatch({ type: 'LOGOUT' })
}

let refreshTokenPromise: Promise<string> | null = null

/**
 * This method handles the process of refreshing the access token.
 * It prevents multiple requests by checking for an existing promise.
 * In case the refresh process fails it logs out the user and
 * returns a never-resolving promise to block outgoing queries.
 */
export const refreshToken = async (
  cache: ApolloCache<any>,
  store: Store
): Promise<string> => {
  if (refreshTokenPromise) {
    return refreshTokenPromise
  }

  try {
    const refreshToken = getRefreshToken()

    if (!refreshToken) {
      throw new AuthError('No refresh token found')
    }

    if (getIsSamlLogin()) {
      refreshTokenPromise = refreshWithAxios()
        .then(({ accessToken }) => {
          refreshTokenPromise = null

          return accessToken
        })
        .catch((error) => {
          console.log('SAML completed refreshing token', error)

          reportException(
            error ?? new AuthError('Failed to refresh token with SAML')
          )
          logout(cache, store)

          return new Promise(() => {
            /* blocks all requests while user is logged out */
          })
        })

      return refreshTokenPromise
    }

    const cognitoUser = getCurrentCognitoUser()

    if (!cognitoUser) {
      throw new AuthError('No cognito user found')
    }

    refreshTokenPromise = new Promise<string>((resolve) =>
      cognitoUser.refreshSession(
        formatRefreshToken(refreshToken),
        (error, session) => {
          const accessToken = session?.getAccessToken()?.getJwtToken()
          const idToken = session?.getIdToken()?.getJwtToken()

          if (accessToken && refreshToken && idToken) {
            setAccessToken(accessToken)
            setIdToken(idToken)

            refreshTokenPromise = null
            resolve(accessToken)

            return
          }

          reportException(
            error ?? new AuthError('Failed to refresh token with Cognito')
          )
          logout(cache, store)
        }
      )
    )
  } catch (error) {
    reportException(error)
    logout(cache, store)
    refreshTokenPromise = new Promise(() => {
      /* blocks all requests while user is logged out */
    })
  }

  return refreshTokenPromise
}
