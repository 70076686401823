import { makeStyles } from '@material-ui/core'

export const useTimeSlotSelectionStyles = makeStyles(() => ({
  bold: {
    fontWeight: 700,
  },
  normal: {
    fontWeight: 400,
  },
}))
