import * as React from 'react'
import { PhoneField, TextField, EmailField } from '~/controlledContextInputs'
import { NumericField } from '~/controlledContextInputs/NumericField'
import { FhirLeafComponent } from '../FhirLeafComponent'
import { getAutoCompleteLine } from '~/util/fhirHelpers'

export const FhirStringComponent: typeof FhirLeafComponent = ({
  isDisabled,
  questionnaireItem,
}) => {
  const {
    supportedExtensions: { typeVariant },
    maxLength,
  } = questionnaireItem

  if (typeVariant === 'numeric') {
    return (
      <NumericField
        name={questionnaireItem.linkId}
        label={questionnaireItem.getText()}
        disabled={isDisabled}
        required={questionnaireItem.required}
      />
    )
  }

  if (typeVariant === 'email') {
    return (
      <EmailField
        name={questionnaireItem.linkId}
        label={questionnaireItem.getText()}
        disabled={isDisabled}
        required={questionnaireItem.required}
      />
    )
  }

  if (typeVariant === 'phone') {
    return (
      <PhoneField
        name={questionnaireItem.linkId}
        label={questionnaireItem.getText()}
        disabled={isDisabled}
        required={questionnaireItem.required}
      />
    )
  }

  if (typeVariant === 'alphanumeric') {
    return (
      <TextField
        name={questionnaireItem.linkId}
        label={questionnaireItem.getText() ?? ''}
        disabled={isDisabled}
        required={questionnaireItem.required}
        maxLength={maxLength}
        inputProps={{
          autoComplete: getAutoCompleteLine(questionnaireItem.linkId),
        }}
        sanitizationRegex={/[^a-zA-Z0-9]/g}
      />
    )
  }

  return (
    <TextField
      name={questionnaireItem.linkId}
      label={questionnaireItem.getText() ?? ''}
      disabled={isDisabled}
      required={questionnaireItem.required}
      maxLength={maxLength}
      inputProps={{
        autoComplete: getAutoCompleteLine(questionnaireItem.linkId),
      }}
    />
  )
}
