import * as React from 'react'
import { ProvinceSelector } from './components/provinceSelector'
import { Title, SubTitle, SymptomsTitle } from './ailmentSelector.styles'
import SymptomSelector from './components/symptomFilter'
import { AilmentType } from '~/util/minorAilments/appointmentsUtil'
import { Province } from '~/util/minorAilments/provincesUtil'
import { AppointmentActivityInstance } from '~/lib/appointmentActivity'
import { AppointmentActivityGroup } from '~/lib/appointmentActivityGroup'
import { FormStepContentLayout } from '~/pages/AppointmentIntake/AppointmentIntake.style'
import { useFormContext } from 'react-hook-form'
import { AaiCard } from '~/pages/AppointmentIntake/components/AaiCard'
import { NoServicesAvailable } from '~/pages/AppointmentIntake/components/NoServicesAvailable'
import { AilmentSelectorForm } from '..'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { AppointmentActivity } from '~/graphql/types/schemaNode.type'
import { useGetAvailableProvinces } from '~/pages/AppointmentIntake/hooks/useGetAvailableProvinces'
import Loading from '~/components/loading'

type AilmentSelectorAai = Omit<AppointmentActivityInstance, 'questionnaires'>

type AilmentSelectorPageProps = {
  selectedProvince: string
  appointmentActivityUrlParam: string
  urlParamToActivities: Record<string, AppointmentActivityInstance>
  appointmentActivityGroup: AppointmentActivityGroup
  appointmentActivities: AilmentSelectorAai[]
  hideProvinceSelector: boolean
  provinces: Province[]
  onSubmit: (data: AilmentSelectorForm) => unknown
  isAppointmentActivitiesLoading: boolean
}

export interface SymptomToActivities {
  [key: string]: AilmentSelectorAai[]
}

export const AilmentSelector: React.FC<AilmentSelectorPageProps> = ({
  selectedProvince,
  appointmentActivityUrlParam,
  urlParamToActivities,
  appointmentActivityGroup,
  hideProvinceSelector,
  appointmentActivities,
  provinces,
  onSubmit,
  isAppointmentActivitiesLoading,
}) => {
  const methods = useFormContext<AilmentSelectorForm>()
  const { handleSubmit, control, setValue } = methods
  const onSubmitHandler = handleSubmit(onSubmit)
  const [selectedSymptomGroup, setSelectedSymptomGroup] =
    React.useState<AilmentType>('all-minor-ailments')
  const [selectedApptActivityId, setSelectedApptActivityId] = React.useState('')
  const [filteredApptActivities, setFilteredApptActivities] = React.useState<
    AilmentSelectorAai[]
  >([])
  const [filteredProvinces, setFilteredProvinces] =
    React.useState<Province[]>(provinces)
  const { title, subDescription, children: symptoms } = appointmentActivityGroup
  const { t } = useMedMeTranslation('patient')

  /**
   * Returns an object mapping each symptom's urlParam to a list of activities
   * that are associated with that symptom.
   */
  const symptomToActivitiesMap = React.useMemo(() => {
    const symptomToActivities: SymptomToActivities = {}
    symptoms.forEach((symptom) => {
      symptomToActivities[symptom.urlParam] = []
    })
    appointmentActivities.forEach((activity) => {
      activity.groups.forEach((group) => {
        if (group.urlParam in symptomToActivities) {
          /**
           * only add the activity to the symptom (group) if the symptom is part of Minor Ailments Parent Group
           * some minor ailment activities are part of groups that are not minor ailment sub groups
           */
          symptomToActivities[group.urlParam].push(activity)
        }
      })
    })
    return symptomToActivities
  }, [symptoms, appointmentActivities])

  const { availableProvinces, loading } = useGetAvailableProvinces(
    appointmentActivityGroup.id
  )

  React.useEffect(() => {
    const isValidActivity = (activity: AppointmentActivity) => {
      return !!urlParamToActivities[activity.urlParam]
    }

    const getAppointmentActivityByUrlParam = (
      activity: AppointmentActivity
    ) => {
      return urlParamToActivities[activity.urlParam]
    }

    const getSelectedSymptomGroup = (symptom: string) => {
      return appointmentActivityGroup.children.find(
        (group) => group.urlParam === symptom
      )
    }

    const getSelectedSymptomGroupApptActivities = (
      selectedSymptomGroup: string
    ) => {
      const currentGroup = getSelectedSymptomGroup(selectedSymptomGroup)
      if (!currentGroup) {
        return []
      }
      return currentGroup.appointmentActivity
        .filter(isValidActivity)
        .map(getAppointmentActivityByUrlParam)
    }

    if (
      appointmentActivityUrlParam !== '' &&
      selectedSymptomGroup === 'all-minor-ailments'
    ) {
      const selectedActivity = appointmentActivities.find(
        (activity) => activity.urlParam === appointmentActivityUrlParam
      )
      setSelectedApptActivityId(selectedActivity?.appointmentActivityId || '')
      setFilteredApptActivities(appointmentActivities)
    } else {
      const symptomGroupApptActivities =
        getSelectedSymptomGroupApptActivities(selectedSymptomGroup)
      setFilteredApptActivities(symptomGroupApptActivities)
    }

    if (!loading && availableProvinces) {
      setFilteredProvinces(
        provinces.filter(({ value }) => availableProvinces.includes(value))
      )
    }
  }, [
    selectedSymptomGroup,
    loading,
    availableProvinces,
    provinces,
    appointmentActivityUrlParam,
    urlParamToActivities,
    appointmentActivityGroup.children,
    appointmentActivities,
  ])

  React.useEffect(() => {
    if (filteredProvinces.length === 1)
      setValue('province', filteredProvinces[0].value)
  }, [filteredProvinces, setValue])

  return (
    <FormStepContentLayout data-testid="ailmentSelector">
      <form onSubmit={onSubmitHandler} data-testid="minorAilmentSelectorForm">
        <Title>{title}</Title>
        <SubTitle>{subDescription}</SubTitle>
        {!hideProvinceSelector &&
          appointmentActivityUrlParam === '' &&
          filteredProvinces.length > 1 && (
            <ProvinceSelector
              provinces={filteredProvinces}
              name="province"
              label="Province"
              control={control}
              methods={methods}
              selectedProvince={selectedProvince}
            />
          )}

        {selectedProvince !== '' && isAppointmentActivitiesLoading && (
          <Loading title={t('loading.title.loading')} />
        )}

        {selectedProvince !== '' && !isAppointmentActivitiesLoading && (
          <div data-testid="symptomSelector">
            {appointmentActivityUrlParam === '' && (
              <SymptomSelector
                name="symptom"
                label="Symptoms"
                symptomToActivities={symptomToActivitiesMap}
                symptoms={symptoms}
                selectedSymptom={selectedSymptomGroup}
                setSelectedSymptom={setSelectedSymptomGroup}
                control={control}
                methods={methods}
                setValue={setValue}
              />
            )}
            <SymptomsTitle>{t('selectAilment')}</SymptomsTitle>
            {
              <div
                data-testid="ailmentAppointmentList"
                style={{ display: 'grid', rowGap: '1.5rem' }}
              >
                {filteredApptActivities.length > 0 ? (
                  filteredApptActivities.map((aai, index) => {
                    const isCardSelected =
                      selectedApptActivityId === aai.appointmentActivityId
                    return (
                      <div
                        key={index}
                        data-testid={`apptType${aai.appointmentActivityId}`}
                        data-cy={aai.name}
                      >
                        <AaiCard
                          aai={{ ...aai, questionnaires: [] }}
                          selected={isCardSelected}
                          onExpand={setSelectedApptActivityId}
                          onCollapse={setSelectedApptActivityId}
                          onSubmit={(id) => {
                            setValue('appointmentId', id)
                          }}
                          isAvailable={!aai.isPrivate || Boolean('')}
                        />
                      </div>
                    )
                  })
                ) : (
                  <div data-testid="noServicesAvailable">
                    <NoServicesAvailable />
                  </div>
                )}
              </div>
            }
          </div>
        )}
      </form>
    </FormStepContentLayout>
  )
}
