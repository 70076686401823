import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getIsAdmin, getIsPharmacyStaffReadOnly } from '~/auth'
import { EnterpriseEnums } from '~/shared/constants/enterpriseConstants'
import { getEnterpriseCode } from '~/util/enterpriseMethods'
import { getPharmacyId, getTenantId } from '~/lib/tenantMetadata'
import { FeatureFlag } from './featureFlags'
import { isLocalHost } from '~/util/envMethods'

type WrappedComponent = Parameters<ReturnType<typeof withLDProvider>>[0]

type PharmacistRole = 'admin' | 'pharmacyStaffReadOnly' | 'pharmacist'

type LocationContext = {
  location: {
    key: string
    pharmacyId: string
    province?: string
    country?: string
  }
}

type PharmacistContext = {
  pharmacist: {
    key: string
    role: PharmacistRole
    languageCode?: string
  }
}

type FeatureGatingContext = {
  kind: 'multi'
  organization: {
    key: string
    tenantId: string
    enterpriseCode: '' | EnterpriseEnums
    hostname: string
  }
  location?: LocationContext['location']
  pharmacist?: PharmacistContext['pharmacist']
}

function getPharmacistRole(): PharmacistRole {
  const isAdmin = getIsAdmin()
  if (isAdmin) {
    return 'admin'
  }

  const isReadOnly = getIsPharmacyStaffReadOnly()
  if (isReadOnly) {
    return 'pharmacyStaffReadOnly'
  }

  return 'pharmacist'
}

function logIfLocalHost(data: unknown, key = 'Context') {
  if (isLocalHost) {
    console.log(`[FeatureGating] ${key}:`, data)
  }
}

/**
 * Enhances a React component with LaunchDarkly feature gating capabilities.
 * If feature gating is enabled and the client ID is present, it wraps the component with LaunchDarkly provider,
 * providing the necessary feature gating context. Otherwise, it logs an error and returns the original component.
 *
 * Note: Feature flags might not be available immediately after rendering since we do not block UI rendering while
 * feature flags are loading. Thus, components should gracefully handle scenarios where feature flags are not yet available.
 *
 * @param {React.ComponentType} wrappedComponent - The component to enhance with feature gating.
 * @returns {React.ComponentType} - The enhanced component.
 */
export function withFeatureGatingProvider(wrappedComponent: WrappedComponent) {
  const clientSideID = process.env.LAUNCH_DARKLY_CLIENT_ID as string

  if (!clientSideID) {
    // TODO: replace with logging error to Sentry
    // https://medmehealth.atlassian.net/browse/NEB-889
    console.error(
      '[FeatureGating] Cannot set up feature flags. Client ID is missing'
    )
    return wrappedComponent
  }

  const hostname = window.location.hostname
  const tenantId = getTenantId()
  const enterpriseCode = getEnterpriseCode()

  const organizationContext = {
    organization: {
      key: tenantId,
      tenantId,
      enterpriseCode,
      hostname,
    },
  }

  const pharmacyId = getPharmacyId(true)
  let locationContext: LocationContext | undefined
  if (pharmacyId) {
    locationContext = {
      location: {
        key: pharmacyId,
        pharmacyId,
      },
    }
  }

  const context: FeatureGatingContext = {
    kind: 'multi',
    ...organizationContext,
    ...locationContext,
  }

  logIfLocalHost(context)

  return withLDProvider({
    clientSideID,
    context,
    options: {
      bootstrap: 'localStorage',
      streaming: false,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(wrappedComponent)
}

/**
 * useAllFeatureFlags is a custom hook which returns all feature flags. It is a wrapper around LaunchDarkly's useFlags.
 */
export const useAllFeatureFlags = useFlags

/**
 * Retrieves the value of a specific feature flag, with an option for a default value if the flag is not found.
 * If feature gating is disabled, it falls back to the old local feature framework.
 *
 * @param {FeatureFlag} flag - The key of the feature flag to retrieve.
 * @param {unknown} [defaultValue=false] - The default value to return if the feature flag is not found.
 * @returns {any} - The value of the feature flag or the default value if the flag is not found.
 */
export function useFeatureFlag(
  flag: FeatureFlag,
  defaultValue: unknown = false
) {
  const allFlags = useAllFeatureFlags()

  return allFlags[flag] ?? defaultValue
}

type UseUpdateFeatureGatingContextProps = {
  pharmacy?: { pharmacyId?: string; province?: string; country?: string }
  pharmacist?: { pharmacistId?: string; languageCode?: string }
}

/**
 * Updates the feature gating location and pharmacist contexts based on pharmacy and pharmacist information.
 *
 * @param {UseUpdateFeatureGatingContextProps} props - The props containing pharmacy and pharmacist information.
 */
export function useUpdateFeatureGatingContext({
  pharmacy: { pharmacyId, province, country } = {},
  pharmacist: { pharmacistId, languageCode } = {},
}: UseUpdateFeatureGatingContextProps) {
  const ldClient = useLDClient()

  useEffect(() => {
    if (ldClient) {
      const currentContext = ldClient.getContext() as FeatureGatingContext

      let locationContext: LocationContext | undefined
      if (pharmacyId) {
        locationContext = {
          location: {
            key: pharmacyId,
            pharmacyId,
            province,
            country,
          },
        }
      }

      let pharmacistContext: PharmacistContext | undefined
      if (pharmacistId) {
        pharmacistContext = {
          pharmacist: {
            key: pharmacistId,
            role: getPharmacistRole(),
            languageCode: languageCode,
          },
        }
      }

      if (locationContext || pharmacistContext) {
        const newContext: FeatureGatingContext = {
          ...currentContext,
          ...locationContext,
          ...pharmacistContext,
        }
        logIfLocalHost(newContext)
        ldClient.identify(newContext, undefined, (error, flags) => {
          if (error) {
            // TODO: replace with logging error to Sentry
            // https://medmehealth.atlassian.net/browse/NEB-889
            console.error(
              '[FeatureGating] Cannot update context. Error:',
              error
            )
          }
          logIfLocalHost(flags, 'Flags')
        })
      }
    }
  }, [country, languageCode, ldClient, pharmacistId, pharmacyId, province])
}

/**
 * Retrieves pharmacy and pharmacist information from Redux state and updates the feature gating context accordingly.
 * Should be used once in the root component of the application that is wrapped with the FeatureGating and Redux providers.
 */
export function useUpdateFeatureGatingContextFromRedux() {
  const {
    id: pharmacyId,
    province,
    country,
  } = useSelector(
    (state: {
      pharmacy: { id: string; province?: string; country?: string }
    }) => state.pharmacy
  )
  const { id: pharmacistId, languageCode } = useSelector(
    (state: { pharmacist: { id: string; languageCode?: string } }) =>
      state.pharmacist
  )

  const allFlags = useAllFeatureFlags()

  useEffect(() => {
    logIfLocalHost({ ...allFlags }, 'All Flags')
  }, [allFlags])

  useUpdateFeatureGatingContext({
    pharmacy: { pharmacyId, province, country },
    pharmacist: { pharmacistId, languageCode },
  })
}
