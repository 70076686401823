import * as React from 'react'
import {
  ailmentSelector,
  reviewAndConfirm,
  completion,
  storeTimeslotSelector,
  consent,
  activityLanding,
  activityGroupLanding,
  getFhirFormStep,
} from '../../jsonFormSteps'

import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'
import { createFormStep, FormStep } from '../../createFormStep'

// Medical Screening
import { fhirMAMedicalScreeningIndiesABJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/AB/fhirMAMedicalScreeningIndiesABJson'
import { fhirMAMedicalScreeningSdmABJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/AB/fhirMAMedicalScreeningSdmABJson'
import { fhirMAMedicalScreeningSdmMBJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/MB/fhirMAMedicalScreeningSdmMBJson'
import { fhirMAMedicalScreeningSdmONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/ON/fhirMAMedicalScreeningSdmONJson'
import { fhirMAMedicalScreeningSdmNSJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/NS/fhirMAMedicalScreeningSdmNSJson'
import { fhirMAMedicalScreeningSdmSKJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/SK/fhirMAMedicalScreeningSdmSKJson'
import { fhirReimbursedMAMedicalScreeningSdmNLJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/NL/fhirReimbursedMAMedicalScreeningSdmNLJson'
import { fhirNotReimbursedMAMedicalScreeningSdmNLJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/NL/fhirNotReimbursedMAMedicalScreeningSdmNLJson'
import { fhirMAMedicalScreeningSdmBCJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/BC/fhirMAMedicalScreeningSdmBCJson'
import { fhirMedicalScreeningSdmPaxlovidJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/fhirMedicalScreeningSdmPaxlovidJson'
import { fhirEDMedicalScreeningSdmSKJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/SK/fhirEDMedicalScreeningSdmSKJson'
import { fhirEDMedicalScreeningSdmABJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/AB/fhirEDMedicalScreeningSdmABJson'
import { fhirReimbursedMAMedicalScreeningSdmNBJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/NB/fhirReimbursedMAMedicalScreeningSdmNBJson'
import { fhirReimbursedMAMedicalScreeningSdmNBJson as frenchFhirReimbursedMAMedicalScreeningSdmNBJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/NB/fr/fhirReimbursedMAMedicalScreeningSdmNBJson'
import { fhirNotReimbursedMAMedicalScreeningSdmNBJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/NB/fhirNotReimbursedMAMedicalScreeningSdmNBJson'
import { fhirNotReimbursedMAMedicalScreeningSdmNBJson as frenchFhirNotReimbursedMAMedicalScreeningSdmNBJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/NB/fr/fhirNotReimbursedMAMedicalScreeningSdmNBJson'
import { fhirMAMedicalScreeningSdmQCJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/QC/fhirMAMedicalScreeningSdmQCJson'
import { fhirMAMedicalScreeningSdmQCJson as frenchFhirMAMedicalScreeningSdmQCJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/SDM/MA/QC/fr/fhirMAMedicalScreeningSdmQCJson'

// ON Indies MA:
import { fhirMedicalScreeningAllergiesIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningAllergiesIndiesONJson'
import { fhirMedicalScreeningUrinaryTractInfectionIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningUrinaryTractInfectionIndiesONJson'
import { fhirMedicalScreeningPinkEyeIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningPinkEyeIndiesONJson'
import { fhirMedicalScreeningPeriodCrampsIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningPeriodCrampsIndiesONJson'
import { fhirMedicalScreeningImpetigoIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningImpetigoIndiesONJson'
import { fhirMedicalScreeningInsectBitesIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningInsectBitesIndiesONJson'
import { fhirMedicalScreeningColdSoresIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningColdSoresIndiesONJson'
import { fhirMedicalScreeningTicksIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningTicksIndiesONJson'
import { fhirMedicalScreeningMuscleAchesIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningMuscleAchesIndiesONJson'
import { fhirMedicalScreeningHemorrhoidsIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningHemorrhoidsIndiesONJson'
import { fhirMedicalScreeningOralThrushIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningOralThrushIndiesONJson'
import { fhirMedicalScreeningDermatitisIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningDermatitisIndiesONJson'
import { fhirMedicalScreeningHeartBurnIndiesONJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/Indies/MA/ON/fhirMedicalScreeningHeartBurnIndiesONJson'

// US Medical and Eligibility
import { fhirMedicalScreeningUTIPreventionIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningUTIPreventionIDJson'
import { fhirMedicalScreeningUrinaryTractInfectionIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningUrinaryTractInfectionIDJson'
import { fhirMedicalScreeningAcneIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningAcneIDJson'
import { fhirMedicalScreeningPinkEyeIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningPinkEyeIDJson'
import { fhirMedicalScreeningSmokingCessationIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningSmokingCessationIDJson'
import { fhirMedicalScreeningFluProphylaxisIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningFluProphylaxisIDJson'
import { fhirMedicalScreeningFluTreatmentIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningFluTreatmentIDJson'
import { fhirMedicalScreeningLowTestosteroneIDJson } from '../../jsonFormSteps/getFhirFormStep/fhirJsons/MedicalScreening/USA/ID/fhirMedicalScreeningLowTestosteroneIDJson'

import { FhirQuestionnaire } from '~/lib/fhir/fhirQuestionnaire'
import { convertQuestionnaireToFhirFormStep } from './convertQuestionnaireToFhirFormStep'
import { Questionnaire } from '~/graphql/types/schemaNode.type'
import { useLanguageForPatient } from '~/hooks/useLanguageForPatient'

const pipe =
  <T>(...fns: Array<(arg: T) => T>) =>
  (value: T) =>
    fns.reduce((acc, fn) => fn(acc), value)

const filterWithEnableWhen =
  (state: ReturnType<typeof useBookingContext>['state']) =>
  (array: FormStep[]) =>
    array.filter((ele) => ele.enableWhen?.(state) ?? true)

export const useIntakeJson = (): {
  formSteps: FormStep[]
} => {
  const [formSteps, setFormSteps] = React.useState<FormStep[]>([])
  const { state, setQuestionnaires } = useBookingContext()
  const { i18n } = useMedMeTranslation()
  const { languageFori18n, languageForFHIR } = useLanguageForPatient()
  const timeSlotSelectionRef = React.useRef<FormStep[]>([])

  React.useEffect(() => {
    const getCards = () => {
      const activityTypeSelection = pipe(filterWithEnableWhen(state))([
        createFormStep(ailmentSelector),
        createFormStep(activityLanding),
        createFormStep(activityGroupLanding),
      ])

      const postActivityTypeSelection = pipe(filterWithEnableWhen(state))([
        // Indies MA
        createFormStep(fhirMAMedicalScreeningIndiesABJson),
        createFormStep(fhirMedicalScreeningAllergiesIndiesONJson),
        createFormStep(fhirMedicalScreeningUrinaryTractInfectionIndiesONJson),
        createFormStep(fhirMedicalScreeningPinkEyeIndiesONJson),
        createFormStep(fhirMedicalScreeningPeriodCrampsIndiesONJson),
        createFormStep(fhirMedicalScreeningImpetigoIndiesONJson),
        createFormStep(fhirMedicalScreeningInsectBitesIndiesONJson),
        createFormStep(fhirMedicalScreeningColdSoresIndiesONJson),
        createFormStep(fhirMedicalScreeningTicksIndiesONJson),
        createFormStep(fhirMedicalScreeningMuscleAchesIndiesONJson),
        createFormStep(fhirMedicalScreeningHemorrhoidsIndiesONJson),
        createFormStep(fhirMedicalScreeningOralThrushIndiesONJson),
        createFormStep(fhirMedicalScreeningDermatitisIndiesONJson),
        createFormStep(fhirMedicalScreeningHeartBurnIndiesONJson),
        // USA
        createFormStep(fhirMedicalScreeningUTIPreventionIDJson),
        createFormStep(fhirMedicalScreeningUrinaryTractInfectionIDJson),
        createFormStep(fhirMedicalScreeningPinkEyeIDJson),
        createFormStep(fhirMedicalScreeningAcneIDJson),
        createFormStep(fhirMedicalScreeningSmokingCessationIDJson),
        createFormStep(fhirMedicalScreeningFluProphylaxisIDJson),
        createFormStep(fhirMedicalScreeningFluTreatmentIDJson),
        createFormStep(fhirMedicalScreeningLowTestosteroneIDJson),

        // SDM MA
        createFormStep(fhirMAMedicalScreeningSdmABJson),
        createFormStep(fhirMAMedicalScreeningSdmMBJson),
        createFormStep(fhirMAMedicalScreeningSdmONJson),
        createFormStep(fhirMAMedicalScreeningSdmNSJson),
        createFormStep(fhirMAMedicalScreeningSdmSKJson),
        createFormStep(fhirReimbursedMAMedicalScreeningSdmNLJson),
        createFormStep(fhirNotReimbursedMAMedicalScreeningSdmNLJson),
        createFormStep(fhirMAMedicalScreeningSdmBCJson),
        createFormStep(fhirReimbursedMAMedicalScreeningSdmNBJson),
        createFormStep(fhirNotReimbursedMAMedicalScreeningSdmNBJson),
        createFormStep(fhirMAMedicalScreeningSdmQCJson),

        // SDM MA - French
        createFormStep(frenchFhirReimbursedMAMedicalScreeningSdmNBJson),
        createFormStep(frenchFhirNotReimbursedMAMedicalScreeningSdmNBJson),
        createFormStep(frenchFhirMAMedicalScreeningSdmQCJson),

        // SDM Non-MA
        createFormStep(fhirMedicalScreeningSdmPaxlovidJson),
        createFormStep(fhirEDMedicalScreeningSdmSKJson),
        createFormStep(fhirEDMedicalScreeningSdmABJson),
      ])

      const timeSlotSelection = pipe(filterWithEnableWhen(state))([
        createFormStep(storeTimeslotSelector),
      ])

      /*
        This patch is needed because when questionnaires are mapped to the store,
        they are loaded only when the user selects a time slot. This causes the form
        to rerender, and the form steps are recreated. When TimeSlotSelection re-mounts
        due to form rerender, it triggers a reset timeslot API, which breaks the flow.
        The ref here allows us to avoid step recreation on form rerender.
      */
      if (timeSlotSelectionRef.current.length !== timeSlotSelection.length) {
        timeSlotSelectionRef.current = timeSlotSelection
      }

      const generateFormSteps = () => {
        const questionnaires = state.appointmentActivity.questionnaires
        // use fhir questionnaires stored in BE if exists
        if (questionnaires && questionnaires.length > 0) {
          return questionnaires.map((questionnaire: Questionnaire) => {
            const formStep = convertQuestionnaireToFhirFormStep(
              questionnaire,
              languageForFHIR
            )
            state.initial.appointment.questionnaireResponses.forEach(
              (questionnaireResponse) => {
                if (
                  formStep.versionId ===
                  questionnaireResponse.questionnaireVersionId
                ) {
                  formStep.fhir.setAnswerFromQuestionnaireResponse(
                    questionnaireResponse.fhirJson
                  )
                }
              }
            )

            formStep.metadata.isPharmacistOnly = questionnaire.isPharmacistOnly

            return formStep
          })
        }

        // TODO: remove this once we fully migrate all the intake_forms
        // fallback to old R4.3 fhir questionnaires that are hardcoded in the FE if not
        return state.appointmentActivity.intakeForms.map((form) => {
          const formStep = getFhirFormStep(form, i18n.language)
          return formStep
        })
      }

      const appointmentDynamicCards: FormStep[] =
        generateFormSteps().map(createFormStep)

      const postAppointmentFixedCards = [createFormStep(consent)]

      const tailIntakeCards = [
        createFormStep(reviewAndConfirm),
        createFormStep(completion),
      ]
      const cards: FormStep[] = [
        ...activityTypeSelection,
        ...postActivityTypeSelection,
        ...timeSlotSelectionRef.current,
        ...appointmentDynamicCards,
        ...postAppointmentFixedCards,
        ...tailIntakeCards,
      ]

      return cards
    }

    const cards = getCards()

    const visibleCards = cards
      .filter((step) => !step.metadata.isPharmacistOnly) // filter out pharmacist only cards
      .filter((step) =>
        // filter out cards that don't match the language if language is set
        step.metadata.languageCode
          ? step.metadata.languageCode === languageFori18n
          : true
      )

    const isSame =
      formSteps.length === visibleCards.length &&
      formSteps.every(
        (fs, index) => fs.metadata.id === visibleCards[index].metadata.id
      )

    if (!isSame) {
      setFormSteps(visibleCards)
      setQuestionnaires(
        cards.reduce<FhirQuestionnaire[]>((acc, card) => {
          if (card.questionnaire) {
            acc.push(card.questionnaire)
          }
          return acc
        }, [])
      )
    }
    // Deps are optimized, if FormSteps aren't rerendering properly can try updating this array
  }, [
    state.initial.appointment,
    state.appointmentActivity,
    state.scope,
    languageFori18n,
  ])

  return {
    formSteps: formSteps,
  }
}
