export const isLocalHost =
  window.location.hostname.includes('localhost') ||
  window.location.hostname.includes('.local')

export const isDev =
  window.location.hostname.includes('.development.medmeapp.com') ||
  window.location.hostname.includes('dev.medmeapp.com') ||
  window.location.hostname.includes('.medme.io') ||
  window.location.hostname.includes('consultation-dev.centrerx.com') ||
  window.location.hostname.includes('consultation-dev.jeancoutu.com') ||
  window.location.hostname.includes('consultation-dev.brunet.ca') ||
  window.location.hostname.includes('scheduler-dev.pharmaprix.ca') ||
  (isLocalHost &&
    (process.env.GRAPHQL_ENDPOINT?.includes('dev') ||
      process.env.GRAPHQL_ENDPOINT?.includes('localhost')))

export const isSandbox =
  window.location.hostname.includes('sandbox.medmeapp.com') ||
  window.location.hostname.includes('.medmesandbox.com') ||
  window.location.hostname.includes('scheduler-sandbox.pharmaprix.ca') ||
  (isLocalHost && process.env.GRAPHQL_ENDPOINT?.includes('sandbox'))

export const isStaging =
  window.location.hostname.includes('.staging.medmeapp.com') ||
  window.location.hostname.includes('scheduler-sit.pharmaprix.ca') ||
  window.location.hostname.includes('scheduler-pt.pharmaprix.ca') ||
  (isLocalHost && process.env.GRAPHQL_ENDPOINT?.includes('staging'))

export const isPatientUI =
  /^\/(?:lcl\/)?(?:\d+\/)?(?!pharmacist)(?:schedule|public|patient|intake|documentationLink|booking|bookingLink)(?:\/|$)/.test(
    window.location.pathname
  ) || /\/\d+\/[a-zA-Z]{1,2}\/.+$/.test(window.location.pathname)

/**
 * Optimistic logical derivation of `isProd` based on existing definitions of:
 * `IsDev*`, `isSandbox`, `isStaging`, `isDevUS`, `isProdUS`, and `isLocalHost` in `envMethods.ts`.
 *
 * Assumes the definitions used to derive `isProd` are accurate + complete + maintained to current.
 *
 * Overall this is a brittle and error prone approach that should be replaced in future with 12-factor
 * inspired deployments (to eliminate per-client hardcoding, etc) and the deployment environment available
 * via client-exposed env var.
 *
 * @todo this whole approach should be deprecated and instead the deploy env explicitly set via env var
 * @see useAnalytics
 */
export const isProd = !isDev && !isSandbox && !isLocalHost

export const getEnvironmentName = () => {
  switch (true) {
    case isDev:
      return 'development'
    case isSandbox:
      return 'sandbox'
    case isStaging:
      return 'staging'
    // Based on isProd definition, the production environment should be the default
    default:
      return 'production'
  }
}
