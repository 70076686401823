import * as React from 'react'
import { useMedMeTranslation } from '~/hooks/useMedMeTranslation'

import { BookingDetails, FormStepDetails } from './BookingDetails'
import Modal from '~/components/modal'

import {
  BookingDetailsWrapperLayout,
  StickyContainer,
  BookingDetailsHeader,
  Title,
  BookingDetailsHeaderBase,
} from './BookingDetails.style'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FormManagerContext } from '../../FormManager'
import { useBookingContext } from '~/pages/AppointmentIntake/contexts'
import moment from 'moment'
import {
  formatPharmacyAddress,
  formatAppointmentInfo,
} from '~/util/formatDisplay'
import { getAaiTitleAndSubtitle } from '~/lib/appointmentActivity'

type BookingDetailsWrapperProps = {
  children: React.ReactNode
}

export const BookingDetailsWrapper: React.FC<BookingDetailsWrapperProps> = ({
  children,
}) => {
  const { formSteps, formStepsIndex } = React.useContext(FormManagerContext)
  const {
    deviceType,
    state: { appointmentActivity, pharmacy, appointment },
  } = useBookingContext()
  const [showModal, setShowModal] = React.useState(false)
  const { t } = useMedMeTranslation('patient')

  const aaiTitleAndSubtitle = getAaiTitleAndSubtitle(appointmentActivity)
  const numberOfPeople = appointment.startDateTime
    ? t('bookingFor', { count: appointment?.noOfPeople })
    : ''
  const timeslot =
    appointment.startDateTime && pharmacy.timeZone
      ? `${moment
          .utc(appointment.startDateTime)
          .tz(pharmacy.timeZone)
          .format('llll')}`
      : ''

  const content = [
    formatPharmacyAddress(pharmacy),
    formatAppointmentInfo(aaiTitleAndSubtitle, numberOfPeople, timeslot),
  ]
    .filter(Boolean)
    .join('\n\n')

  const formStepDetails: FormStepDetails = React.useMemo(() => {
    return [
      {
        groupLabel: '',
        items: formSteps.map(({ metadata }, i) => {
          return {
            label: t(metadata.name),
            isComplete: i < formStepsIndex,
            isCurrent: i === formStepsIndex,
            isDisabled: i > formStepsIndex,
            isHidden: !metadata.showOnSteps,
          }
        }),
      },
    ]
  }, [formSteps, formStepsIndex, t])

  const hideBookingDetails = appointmentActivity.intakeForms.length === 0

  const isDesktop = deviceType === 'desktop'

  return (
    <BookingDetailsWrapperLayout flexDirection={isDesktop ? 'row' : 'column'}>
      {!isDesktop && (
        <>
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <BookingDetails
              hideBookingDetails={hideBookingDetails}
              content={content}
              formStepDetails={formStepDetails}
            />
          </Modal>
          <BookingDetailsHeaderBase />
          <BookingDetailsHeader onClick={() => setShowModal(true)}>
            <Title>{t('details')}</Title>
            <ExpandMoreIcon />
          </BookingDetailsHeader>
        </>
      )}
      <div style={{ width: '100%', height: '100%' }}>{children}</div>

      {isDesktop && (
        <StickyContainer>
          <BookingDetails
            hideBookingDetails={hideBookingDetails}
            content={content}
            formStepDetails={formStepDetails}
          />
        </StickyContainer>
      )}
    </BookingDetailsWrapperLayout>
  )
}
